import AdminReport from "./AdminReport";
import SigninCallback from "./SigninCallback";
import TestFile from "./TestFile";
import AdminFooter from "./global/AdminFooter/AdminFooter";
import AdminHeader from "./global/AdminHeader/AdminHeader";
import AdminDashboard from "./AdminDashboard/AdminDashboard";
import RegisteredKits from "./AdminLabPortal/RegisteredKits/RegisteredKits";
import ReceivedKits from "./AdminLabPortal/ReceivedKits/ReceivedKits";
import ValidatedKits from "./AdminLabPortal/ValidatedKits/ValidatedKits";
import OnHoldKits from "./AdminLabPortal/OnHoldKits/OnHoldKits";
import CreateBatch from "./AdminLabPortal/CreateBatch/CreateBatch";
import ActiveBatches from "./AdminLabPortal/ActiveBatches/ActiveBatches";
import CancelledBatches from "./AdminLabPortal/CancelledBatches/CancelledBatches";
import CompletedBatches from "./AdminLabPortal/CompletedBatches/CompletedBatches";
import CompletedKits from "./AdminLabPortal/CompletedKits/CompletedKits";
import RejectedKits from "./AdminLabPortal/RejectedKits/RejectedKits";
import PrescriptionList from "./AdminPhysicianPortal/PrescriptionList/PrescriptionList";
import PrescriptionFormSubmissions
    from "./AdminPhysicianPortal/PrescriptionFormSubmissions/PrescriptionFormSubmissions";
import PhysicianPrescriptionFilled from "./AdminPhysicianPortal/PhysicianPrescriptionFilled";
import PhysicianPrescriptionForm from "./Jotform/PhysicianPrescriptionForm"

import GenerateKits from "./AdminServiceDashboard/GenerateKits/GenerateKits";
import UnassignedOrders from "./AdminServiceDashboard/UnassignedOrders/UnassignedOrders";
import OrderedKits from "./AdminServiceDashboard/OrderedKits/OrderedKits";
import QuizList from "./AdminLabPortal/QuizList/QuizList";

export default {
    AdminReport,
    AdminDashboard,
    AdminFooter,
    AdminHeader,
    SigninCallback,
    TestFile,
    RegisteredKits,
    ReceivedKits,
    ValidatedKits,
    CreateBatch,
    OnHoldKits,
    ActiveBatches,
    CancelledBatches,
    CompletedBatches,
    CompletedKits,
    RejectedKits,
    PrescriptionList,
    PrescriptionFormSubmissions,
    PhysicianPrescriptionFilled,
    PhysicianPrescriptionForm,
    GenerateKits,
    UnassignedOrders,
    OrderedKits,
    QuizList,
}