import { Row, Col } from 'antd';
import PHFaceRangeSlider from '../../Global/PHFaceRangeSlider/PHFaceRangeSlider';
import {
    face_range_slider, face_range_slider_1, face_range_slider_mobile,
    face_range_slider_1_mobile
} from '@ph/common/assets';
import style from './ReportSections.module.scss';
import ReportProduct from '../ReportContainer/ReportProduct';
import { useCart } from '@ph/common/context/CartContext';
import { REPORT_PLAN_DETAILS } from '@ph/common/constants/Report/ReportConstants';
import ProductHelpLabel from '../ReportContainer/ProductHelpLabel';
import { addLeadingZero, getFirstArea } from '../reportUtils';
import ReportSectionHeader from './ReportSectionHeader';
import DescriptionContainer from '../ReportContainer/DescriptionContainer';
import { KIT_TYPES } from '@ph/common/constants/KitConstants';
import { REPORT_PAGE_CONFIG } from '@ph/common/constants/Report/ReportConstants';

function SensitivitySection({ reportData, kitType }) {

    const recommendedHydrationProduct = reportData?.other_recommendations.filter(product => product.product_id === Number(process.env.REACT_APP_HYDRATION_PRODUCT_ID))
    const recommendedSensitivityProduct = reportData?.other_recommendations.filter(product => product.product_id === Number(process.env.REACT_APP_SENSITIVITY_PRODUCT_ID))
    const { reportPlan } = useCart()
    const productKey = REPORT_PAGE_CONFIG[kitType]?.swabKey

    const customLabel = getFirstArea(reportData?.area_priority)

    return (
        <div className={`${style.handSSection} ${style.sensitivitySection}`}>
            <Row className={style.row}>
                {/* <Col xs={24} xl={12} className={`${style.col2} ${style.first}`}>
                    <HandSHeader headerData={header1} score={reportData?.average_hydration} />
                    <PHFaceRangeSlider
                        rangeMax={'105'}
                        rangeMin={'-5'}
                        cheekValue={reportData.cheek_skin_hydration && Number(reportData.cheek_skin_hydration) !== -1 ? Number(reportData.cheek_skin_hydration) : null}
                        foreheadValue={reportData.forehead_skin_hydration && Number(reportData.forehead_skin_hydration) !== -1 ? Number(reportData.forehead_skin_hydration) : null}
                        chinValue={null}
                        color={"#2E5ACC"}
                        endColor={"#2E5ACC"}
                        domId={'hydration'}
                        backgroundSrc={face_range_slider}
                        sliderLabelRange={['DRY', 'MODERATE', 'OILY']}
                        mobileBackgroundSrc={face_range_slider_mobile}
                    />
                    <div className='show-mobile-hide-desktop'>
                        <ReportProduct product={recommendedHydrationProduct[0]} title={"RECOMMENDED PRODUCT"} first={true} reportPlan={reportPlan} />
                    </div>
                </Col> */}
                <Col xs={24} xl={24} className={`${style.col1} ${style.first}`}>
                    <PHFaceRangeSlider
                        rangeMax={'105'}
                        rangeMin={'-5'}
                        // cheekValue={reportData.skin_site_specific_sensitivity.CK && Number(reportData.skin_site_specific_sensitivity.CK) !== -1 ? Number(reportData.skin_site_specific_sensitivity.CK) : null}
                        foreheadValue={reportData.skin_site_specific_sensitivity[productKey] && Number(reportData.skin_site_specific_sensitivity[productKey]) !== -1 ? Number(reportData.skin_site_specific_sensitivity[productKey]) : null}
                        // chinValue={reportData?.skin_site_specific_sensitivity.CN && Number(reportData.skin_site_specific_sensitivity.CN) !== -1 ? Number(reportData.skin_site_specific_sensitivity.CN) : null}
                        color={"#25A18E"}
                        endColor={"#25A18E"}
                        gradientColor={"#FFFFFF"}
                        domId={'sensitivity'}
                        customLabel={customLabel}
                        smallContainer={true}
                        backgroundSrc={face_range_slider_1}
                        sliderLabelRange={['REGULAR', 'MODERATE', 'INFLAMED']}
                        mobileBackgroundSrc={face_range_slider_1_mobile}
                    />
                    {kitType !== KIT_TYPES.TYPE_D && (
                    <div className='show-mobile-hide-desktop'>
                        <ReportProduct product={recommendedSensitivityProduct[0]} title={"PRODUCT HIGHLIGHT"} first={true} reportPlan={reportPlan} />
                    </div>
                    )}
                </Col>
                {/* <Col xs={24} xl={8}>
                    <DescriptionContainer
                        title='what does this mean for me?'
                        description={<>This Antibiotic Resistance Score reflects the presence and quantity of DNA snippets associated with antibiotic-resistant genes. A higher score indicates a presence of a greater number of resistant genes to various antibiotics. Note that this score is not a diagnostic but rather for your education. Further research is needed to assess the risk of an antibiotic-resistant infection.<br /><br />Questions? Schedule a chat with Parallel’s MD team.</>}
                    />
                </Col> */}
            </Row>

            {kitType !== KIT_TYPES.TYPE_D && (
            <Row className="show-desktop-hide-mobile">

                <Col xs={24} md={12}>
                    {recommendedHydrationProduct[0] && (
                        <ReportProduct product={recommendedHydrationProduct[0]} title={"PRODUCT HIGHLIGHT"} first={true} reportPlan={reportPlan} />
                    )}
                </Col>
                <Col xs={24} md={12}>
                    {recommendedSensitivityProduct[0] && (
                        <ReportProduct product={recommendedSensitivityProduct[0]} title={"PRODUCT HIGHLIGHT"} reportPlan={reportPlan} />
                    )}
                </Col>
                {REPORT_PLAN_DETAILS[reportPlan]?.discount_suggestion_text_large &&
                    <Col xs={24} md={24}>
                        <ProductHelpLabel text={REPORT_PLAN_DETAILS[reportPlan]?.discount_suggestion_text_large} justify='center' />
                    </Col>
                }
            </Row>
            )}
        </div>
    )
}

export default SensitivitySection

const HandSHeader = ({ headerData, score = 26, combined = false }) => {
    return (
        <Row className={style.header}>
            <span className={`${style.reportSectionCount} ${combined ? style.combined : ''} show-mobile-hide-desktop`}>{headerData.count}</span>
            {!combined && (
                <div className={`${style.doubleScore} show-desktop-hide-mobile`}>
                    <span className={style.leftCount}>03</span>
                    <span className={style.rightCount}>04</span>
                </div>
            )}

            <Col xs={7} md={5}>
                <div className={style.reportSectionScore}>
                    {addLeadingZero(score)}
                </div>

            </Col>
            <Col xs={17} md={19}>
                <div className={style.titleContainer}>
                    <div className={style.title}>
                        {headerData.title}
                    </div>
                    <div className={style.subTitle}>
                        {headerData.subTitle}
                    </div>
                </div>
            </Col>
            <Col xs={24} md={24}>
                <div className={style.description}>
                    {headerData.description}
                </div>
            </Col>
        </Row>
    )
}