import React from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import Components from "@ph/common/components";
import AdminComponent from "@ph/admin/src/component";
import Home from "@ph/admin/src/component/Home/index";
import Adminlayout from "@ph/admin/src/layouts";
import { CartProvider } from '@ph/common/context/CartContext';
import { AuthToken } from "./api_services/localstorage";
import { convertToURIPath } from '@ph/common/global/Helpers';
import { LabPortalRoutesList, PhysicianPortalRoutesList, SerivceDashboardRoutesList } from "./utils/nav-menu-items";

const App = () => {

    function AuthorisedPrivateRoute({ children }) {
        const hasToken = !!AuthToken.getToken('ph_admin_token');
        if (!hasToken) {
            // return <Navigate to="/logout" />
            window.location = `${process.env.REACT_APP_AWS_DOMAIN}/login?client_id=${process.env.REACT_APP_AWS_CLIENT_ID}&response_type=${process.env.REACT_APP_AWS_RESPONSE_TYPE}&redirect_uri=${process.env.REACT_APP_AWS_REDIRECT_URI}`;
        }
        // authorized so return child components
        return children;
    }

    return (
        <Router>
            <Routes>
                <Route element={<Adminlayout.LightLayout />}>
                    <Route path="/" element={<AuthorisedPrivateRoute><Home /></AuthorisedPrivateRoute>} />
                    <Route path="dashboard"
                        element={<AuthorisedPrivateRoute><AdminComponent.AdminDashboard /></AuthorisedPrivateRoute>} />
                    {/* <Route path="" element={<AdminComponent.AdminDashboard />} /> */}
                    <Route path="*" element={<Navigate to="/" />} />
                    <Route path="create-batch"
                        element={<AuthorisedPrivateRoute><AdminComponent.CreateBatch /></AuthorisedPrivateRoute>} />
                    <Route path="/signin-callback" element={<AdminComponent.SigninCallback />} />

                    {/* <Route path="tesst-path" element={<AdminComponent.TestFile />} /> */}
                </Route>
                <Route element={<Adminlayout.LabPortalLayout />}>
                    {LabPortalRoutesList.map((routeParams, i) => {
                        return <Route key={'' + i} path={convertToURIPath(routeParams.path, 'lab-portal')}
                            element={
                                <AuthorisedPrivateRoute>{routeParams.component}</AuthorisedPrivateRoute>} />;
                    })}
                </Route>
                <Route element={<Adminlayout.PhysicianPortalLayout />}>
                    {PhysicianPortalRoutesList.map((routeParams, i) => {
                        return <Route key={'' + i} path={convertToURIPath(routeParams.path, 'physician-portal')}
                            element={
                                <AuthorisedPrivateRoute>{routeParams.component}</AuthorisedPrivateRoute>} />;
                    })}
                </Route>
                <Route element={<Adminlayout.ServiceDashboardLayout />}>
                    {SerivceDashboardRoutesList.map((routeParams, i) => {
                        return <Route key={'' + i} path={convertToURIPath(routeParams.path, 'service-dashboard')}
                            element={
                                <AuthorisedPrivateRoute>{routeParams.component}</AuthorisedPrivateRoute>} />;
                    })}
                </Route>
                <Route element={<Adminlayout.PhysicianPortalLayout hideSideBar={true} />}>
                    <Route path="prescription/form-submission/:submittedBy/:submissionId"
                        element={
                            <AuthorisedPrivateRoute><AdminComponent.PrescriptionFormSubmissions /></AuthorisedPrivateRoute>} />
                    <Route path="prescription/form-submission/:submittedBy/:submissionId/:prescriptionOrderId"
                        element={
                            <AuthorisedPrivateRoute><AdminComponent.PrescriptionFormSubmissions /></AuthorisedPrivateRoute>} />
                    <Route path="prescription-form/filled"
                        element={
                            <AuthorisedPrivateRoute><AdminComponent.PhysicianPrescriptionFilled /></AuthorisedPrivateRoute>} />
                </Route>
                <Route>
                    <Route path="report/:kitId"
                        element={<AuthorisedPrivateRoute>
                            <CartProvider>
                                <AdminComponent.AdminReport />
                            </CartProvider>
                        </AuthorisedPrivateRoute>} />
                    {/* <Route path="report/:kitId" element={<AdminComponent.AdminReport />} /> */}
                </Route>
                <Route path="prescription-form/:prescriptionOrderId" element={<AdminComponent.PhysicianPrescriptionForm />} />
            </Routes>
            {/* <AdminComponent.AdminFooter /> */}
        </Router>
    );
}

export default App;
