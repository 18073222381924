import { BODY_AREA } from "@ph/common/constants/Report/ReportConstants";

export const navigateToShopifyUrl = (productItem) => {
    let {shopify_product_url} = productItem;
    if (shopify_product_url) {
        if(productItem.isPrimary) {
            shopify_product_url += '?primary=true';
        }
        if(productItem.isSecondary) {
            shopify_product_url += '?secondary=true';
        }
        window.open(shopify_product_url, '_blank')
        /*if(openInNewTab) {
        } else {
            window.location = shopify_product_url;
        }*/
    }
};

export function generateGradientColors(startColor, endColor, length) {
    // Convert hex colors to RGB values
    const startRGB = hexToRgb(startColor);
    const endRGB = hexToRgb(endColor);

    // Create an array to store the gradient colors
    const gradientColors = [];

    // Calculate color increments for each component (R, G, B)
    const rStep = (endRGB.r - startRGB.r) / (length - 1);
    const gStep = (endRGB.g - startRGB.g) / (length - 1);
    const bStep = (endRGB.b - startRGB.b) / (length - 1);

    // Generate colors for each position in the array
    for (let i = 0; i < length; i++) {
        const r = Math.round(startRGB.r + rStep * i);
        const g = Math.round(startRGB.g + gStep * i);
        const b = Math.round(startRGB.b + bStep * i);

        // Convert RGB values back to hex
        gradientColors.push(rgbToHex(r, g, b));
    }

    return gradientColors;
}

// Helper functions to convert between hex and RGB
function hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

function rgbToHex(r, g, b) {
    return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function componentToHex(c) {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
}

export function capitalizeFirstLetter(str) {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
}

export const sortProducts = (inputItems, real_skin_age, skin_age) => {
    let items = []
    if (real_skin_age < skin_age) {
        // Show blue peptide and remove silk cream
        items = inputItems.filter(product => product.product_id != process.env.REACT_APP_HYDRATION_PRODUCT_ID)
    } else {
        // Show silk cream and remove blue peptide
        items = inputItems.filter(product => product.product_id != process.env.REACT_APP_SKIN_AGE_PRODUCT_ID)
    }
    if (items) {
        let cleansers = []
        let toners = []
        let serums = []
        let others = []
        let sunscreens = []

        for (let i = 0; i < items.length; i++) {
            if (items[i]?.name?.toLowerCase().includes('cleanser')) {
                cleansers.push(items[i])
            } else if (items[i]?.name?.toLowerCase().includes('toner')) {
                toners.push(items[i])
            } else if (items[i]?.name?.toLowerCase().includes('serum')) {
                serums.push(items[i])
            } else if (items[i]?.name?.toLowerCase().includes('toner')) {
                toners.push(items[i])
            } else if (items[i]?.name?.toLowerCase().includes('sunscreen')) {
                sunscreens.push(items[i])
            } else {
                others.push(items[i])
            }

        }
        const sortedItems = [...cleansers, ...toners, ...serums, ...others, ...sunscreens]
        return sortedItems
    }
    return items
}

export const calculateDiscountPercentage = (originalPrice, discountedPrice) => {
    const discountAmount = originalPrice - discountedPrice;
    const discountPercentage = (discountAmount / originalPrice) * 100;

    return Math.round(discountPercentage);
};

export function addLeadingZero(num) {
    if (num) {
        if (Number.isInteger(num)) {
            if (num < 10) {
                return '0' + num; // Add leading zero for integers less than 10
            }
            return num.toString(); // Return as string if 10 or greater
        }
        return num.toString();
    }
    return '00'
}

export const getFirstArea = (areaList) => {
    const allAreas = Object.values(BODY_AREA)
    let parseArea = ""
    if (typeof areaList === 'string') {
        parseArea = areaList
    } else if (Array.isArray(areaList)) {
        if (areaList.length > 0) {
            parseArea = areaList[0];
        } else {
            parseArea = ""
        }
        // Return the first element of the array
    } else if (typeof areaList === 'object') {
        const keys = Object.keys(areaList);
        if (keys.length > 0) {
            parseArea = areaList[keys[0]];
        }
    }
    const isPartOfDefault = allAreas.filter(area => area.toUpperCase() === parseArea.toUpperCase())
    if (isPartOfDefault && isPartOfDefault.length > 0) {
        return parseArea
    } else {
        const splitValues = parseArea.split(" ")
        if (parseArea.length > 10 || splitValues.length > 1) {
            return BODY_AREA.OTHER
        }
    }
    return parseArea
};