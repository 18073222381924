import { NO_ISSUES_TEXT } from "@ph/common/global/Constants"
import { KIT_TYPES } from "../KitConstants"

export const REPORT_SECTIONS = {
    DIVERSITY: 'DIVERSITY',
    SKIN_AGE: 'SKIN AGE',
    ANTIBIOTIC: 'ANTIBIOTIC RESISTANCE',
    HYDRATION: 'HYDRATION',
    SENSITIVITY: 'SENSITIVITY',
    NUTRIENT: 'NUTRIENT',
    SKINCARE: 'SKINCARE',
    PRODUCT: 'PRODUCT'
}

export const REPORT_PLANS = {
    MD03: 'MD03',
    TESTER: 'TESTER'
}

export const REPORT_PLAN_MODAL_TYPES = {
    WELCOME: 'WELCOME',
    DOWNGRADE: 'DOWNGRADE'
}

export const REPORT_PLAN_MODAL_DETAILS = {
    [REPORT_PLAN_MODAL_TYPES.WELCOME]: {
        title: 'Welcome!',
        subTitle: <><span className="font-Neue-medium">You're upgrading to the MD-03 Protocol™.</span> <br />Enjoy exclusive discounted pricing, 24/7 access to Microbiome Dermatology™ doctors, and skin microbiome testing twice a year.</>,
        cancelText: '',
        successText: ''
    },
    [REPORT_PLAN_MODAL_TYPES.DOWNGRADE]: {
        title: 'Oh No!',
        subTitle: <>By removing your phage serum(s), you will lose exclusive discounting, 24/7 access to our physicians, and free testing twice a year.</>,
        cancelText: 'Remove serums',
        successText: 'Keep serum(s) in cart'
    }
}

export const REPORT_PLAN_DETAILS = {
    [REPORT_PLANS.MD03]: {
        cart_title: <><span className="font-Mono-bold">CONGRATULATIONS!</span> As a member of MD-03, you exclusively access up to 51% OFF all add-on products</>,
        cart_addon_title: 'PLAN ADD-ONS',
        show_product_discount: true,
        discount_suggestion_text_short: null,
        discount_suggestion_text_large: null,
        product_price_key: 'md_03_price',
        original_price_key: 'md_03_price',
        discount_price_key: 'price',
        product_variant_key: 'md_03_variant_id',
        product_selling_plan_key: 'md_03_selling_plan_id',
        serum_plan_title: <> as a member of <span className="font-Mono-bold">MD-03</span>, you exclusively access up to< span className="font-Mono-bold" > 51% off </span >all add-on products.</>,
        skin_regimen_title: 'As a member of MD-03, you exclusively access up to 51% OFF all add-on products',
        skin_regimen_subtitle: '',
        welcome_text: 'We are happy to present your MD03 plan'
    },
    [REPORT_PLANS.TESTER]: {
        cart_title: <>Sign up for the MD-03 Protocol™ by <span className="font-Mono-bold">adding a phage serum </span> to get exclusive discounted pricing on all products across the report</>,
        cart_addon_title: 'ADD-ONS',
        show_product_discount: true,
        product_price_key: 'price',
        original_price_key: 'ala_carte_discount_price',
        discount_price_key: 'price',
        product_variant_key: 'variant_id',
        product_selling_plan_key: 'selling_plan_id',
        discount_suggestion_text_short: 'ADD ANY SERUM ABOVE TO ACCESS EXCLUSIVE DISCOUNTED PRICING',
        discount_suggestion_text_large: 'SIGN UP FOR THE MD-03 PROTOCOL™ BY ADDING A PHAGE SERUM ABOVE TO GET EXCLUSIVE DISCOUNTED PRICING ON ALL PRODUCTS ACROSS THE REPORT',
        serum_plan_title: <>Purchase any serum below for <span className="font-Mono-bold">$195/mo</span> to upgrade to <span className="font-Mono-bold">the MD-03 Protocol™</span> and unlock concierge care and exclusive discounts. Get your second serum and beyond for <span className="font-Mono-bold">$95/mo</span>.</>,
        skin_regimen_title: 'Purchase any serum below to upgrade to the MD-03 Protocol™ and unlock exclusive low pricing, two tests a year, and 24/7 access to medical guidance',
        skin_regimen_subtitle: '',
        welcome_text: 'We are happy to present your'
    }
}


export const FACE_AREA = {
    CHIN: 'CN',
    CHEEK: 'CK',
    T_ZONE: 'TZ',
    NO_ISSUE: NO_ISSUES_TEXT
}

export const QUIZ_FACE_AREA = {
    CHIN: 'Chin',
    CHEEK: 'Cheeks',
    T_ZONE: 'Forehead/T-Zone',
    NO_ISSUE: NO_ISSUES_TEXT
}


export const BODY_AREA = {
    CHEST: 'Chest',
    BACK: 'Back',
    BUTT: 'Butt',
    ARMS: 'Arms',
    LEGS: 'Legs',
    INTIMATE_AREAS: 'Intimate Areas',
    FEET: 'Feet',
    HANDS: 'Hands',
    SCALP: 'Scalp',
    SHOULDER: 'Shoulder',
    ARMPIT: 'Armpit',
    NO_ISSUE: NO_ISSUES_TEXT,
    OTHER: 'Other',
};


// Report page section constant

export const REPORT_SECTION_INFO = {
    [REPORT_SECTIONS.DIVERSITY]: {
        count: '01',
        title: 'DIVERSITY',
        subTitle: 'YOUR OVERALL SKIN DIVERSITY',
        description: 'Your Diversity Score is based on the microbiome of your t-zone, cheek, and chin. Click on each facial area below for specific details on beneficial and potentially harmful bacteria. Note that higher microbial diversity is generally correlated with healthy skin.'
    },
    [REPORT_SECTIONS.SKIN_AGE]: {
        count: '02',
        title: 'YOUR SKIN AGE',
        subTitle: 'YOUR OVERALL SKIN AGE',
        description: `Your skin age reflects your microbial diversity, hydration levels, and the balance of beneficial and non-beneficial bacteria. Parallel has identified 7 Hallmarks of Skin Aging. To maintain your best 'Skin Age' prioritize the three highlighted areas based on your results.`
    },
    [REPORT_SECTIONS.HYDRATION]: {
        count: '03',
        title: 'HYDRATION',
        subTitle: 'YOUR SKIN HYDRATION LEVEL',
        description: <>Your hydration score reflects a combination of the data from your oil strips. The higher your score, the more optimal.<br /><br />The hydration levels below are a reflection of oiliness and dryness in those areas. An ideal level is in the mid to upper range (~75 out of 100). Your cheek and forehead can sometimes have drastically different levels.<br /><br /></>
    },
    [REPORT_SECTIONS.SENSITIVITY]: {
        count: '04',
        title: 'SENSITIVITY ',
        subTitle: 'YOUR SKIN SENSITIVITY LEVEL',
        description: <>Your sensitivity score is a measurement of how reactive your skin is to the environment or external stressors. It can be affected by a multitude of factors, including skin microbiome, daily habits, stress, and genetics, amongst others. It is measured on a scale of 1-100, with 100 being the most sensitive.<br /><br />Below, you will find a sensitivity score calculated for each of your three facial areas.</>
    },
    [REPORT_SECTIONS.ANTIBIOTIC]: {
        count: '05',
        title: 'ANTIBIOTIC RESISTANCE',
        subTitle: 'YOUR ANTIBIOTIC RESISTANCE SCORE',
        description: 'Antibiotic resistance is a growing concern. Our Antibiotic Resistance Score is based on the presence and quantity of DNA snippets associated with 51 known antibiotic resistance genes. It is reported on a scale of 1-100 and is calculated based on the relative quantity of resistance genes in your samples compared to our whole cohort.'
    },
    [REPORT_SECTIONS.NUTRIENT]: {
        count: '06',
        title: 'NUTRIENT  RECOMMENDATIONS ',
        description: 'Based on your skin microbiome results and clinical data, here are some nutrients that can help optimize your skin health. Also provided are examples of foods that include these nutrients.'
    },
    [REPORT_SECTIONS.SKINCARE]: {
        count: '07',
        title: 'SKINCARE  INGREDIENT RECOMMENDATIONS',
        description: 'Based on your skin microbiome results and clinical data, here are some skincare ingredients that can help optimize your skin health.'
    },
    [REPORT_SECTIONS.PRODUCT]: {
        count: '08',
        title: 'YOUR IDEAL SKIN REGIMEN',
    }
}

// Aging hallmark constants

export const AGING_HALLMARKS = {
    DEFAULT: '',
    INFLAMMATION: 'inflammation',
    VOLUME_LOSS: 'volume loss',
    DRYNESS: 'dryness',
    LOSS_ELASTICITY: 'loss of elasticity',
    UNEVEN_TEXTURE: 'uneven skin texture',
    COLLAGEN_LOSS: 'collagen loss',
    DYSPIGMENTATION: 'dyspigmentation'
}

export const HALLMARK_DESCRIPTION = {
    [AGING_HALLMARKS.DEFAULT]: {
        title: null,
        description: <>To the left, you will find <span className="font-Neue-medium">The 7 Hallmarks of Skin Aging™.</span> Parallel's products can help you target specific hallmarks and can improve your skin microbiome age. <span className="text-italic">Click </span> on each hallmark to learn more. View each product to learn which of the 7 Hallmarks  it addresses.</>,
        icon: null
    },
    [AGING_HALLMARKS.INFLAMMATION]: {
        title: 'Inflammation',
        description: 'inflammatory markers in the skin marked by redness, swelling, heat, and pain, leading to accelerated aging.',
        short_description: "In response to stressors like UV radiation, pollution, and disease, the body releases inflammatory mediators, like cytokines and free radicals, which can damage skin cell's structure and function. In addition, chronic inflammation can impair the skin's natural repair systems.",
        icon: <svg width="230" height="86" viewBox="0 0 230 86" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 85.749H230C230 85.749 217.803 54.2982 204.697 28.8371C195.525 11.0065 186.352 -2.81133 174.472 0.489912C157.116 5.29352 155.752 23.7173 144.741 42.22C140.451 54.6936 121.79 62.7589 115.01 56.7099C108.229 50.6609 103.96 37.8316 88.6789 37.8316C75.9286 37.8316 70.9076 55.7216 55.5479 64.1822C40.1882 72.6429 28.367 69.6777 0.0197269 85.749H0Z" fill="#2D2926" /></svg>,
        very_short_description: 'redness and irritation flare ups',
    },
    [AGING_HALLMARKS.LOSS_ELASTICITY]: {
        title: 'Loss of Elasticity',
        description: 'Elasticity is the ability of skin to bounce back to its natural setting when stretched. Loss of this leads to deep  creases/wrinkles in the skin.',
        short_description: "Skin elasticity enables rebound after stretching. Reduced elasticity leads to sagging, fine lines, and wrinkles. Aging diminishes crucial proteins for elasticity, exacerbated by stressors such as sun exposure and harsh skincare products.",
        icon: <svg width="232" height="130" viewBox="0 0 232 130" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M182.321 128.117C168.566 127.03 155.793 120.549 144.943 112.312C139.884 108.466 135.139 104.201 129.515 101.149C124.77 98.5569 119.522 96.9055 114.317 95.4003C92.6804 89.087 70.5002 84.467 48.1109 81.6866C39.7698 80.6413 31.3242 79.826 23.4012 77.1084C15.4782 74.3907 8.01509 69.4572 4.29399 62.224C1.78539 57.3322 1.11641 51.7506 1.01189 46.2735C0.886459 39.5421 1.70176 32.3925 5.94548 27.02C8.62132 23.6542 12.426 21.292 16.2516 19.2224C28.0212 12.8045 40.8569 8.18452 54.1106 5.57139C65.3366 3.37636 76.8135 2.60288 88.2695 1.93392C108.108 0.784144 128.24 -0.0102563 147.682 3.9826C169.276 8.43538 189.094 18.6161 208.223 29.2359C216.835 34.0022 225.971 39.563 229.378 48.573C231.114 53.1303 231.155 58.1266 230.863 62.9766C229.232 92.39 221.978 131.252 182.321 128.158V128.117Z" stroke="#2D2926" stroke-width="1.5" stroke-miterlimit="10" />
            <path d="M33.0626 34.2954C35.2158 31.3687 38.2889 29.2573 41.4246 27.3549C45.4802 24.8881 49.7239 22.6931 54.0931 20.7698C60.0719 18.1358 66.3016 15.9826 72.824 14.6028C79.0328 13.244 85.4297 12.6587 91.743 12.4287C95.2341 12.3033 98.7253 12.2615 102.154 12.2615C104.955 12.2615 107.756 12.2615 110.537 12.3242C124.501 12.5123 138.403 13.5576 152.179 16.7979C169.865 20.9789 186.485 29.3827 202.477 38.351C209.647 42.3856 217.236 47.0474 220.204 54.4478C221.71 58.2107 221.856 62.329 221.772 66.3428C221.584 74.224 220.894 82.9205 218.887 91.0525C214.685 108.09 204.567 122.41 182.387 121.135C170.764 120.466 159.852 115.302 150.507 108.571C149.462 107.839 148.458 107.066 147.434 106.292C144.173 103.826 140.912 101.338 137.253 99.4145C133.198 97.2613 128.766 95.8398 124.334 94.5228C106.021 89.0247 87.3739 84.7183 68.6639 81.708C61.7025 80.6 54.6993 79.6384 48.1142 77.1507C41.55 74.6421 35.3622 70.3775 32.1846 64.2314C30.0314 60.0713 29.4042 55.3467 29.237 50.7267C29.0279 45.0197 29.6133 38.9572 33.0208 34.3581V34.2954H33.0626Z" stroke="#2D2926" stroke-width="1.5" stroke-miterlimit="10" />
            <path d="M60.2188 41.59C61.8494 39.1023 64.1907 37.2208 66.6157 35.5066C69.7724 33.2907 73.0963 31.2838 76.5247 29.4859C81.2283 27.0401 86.1828 24.9287 91.5345 23.6325C96.8862 22.3155 102.196 22.0019 107.464 22.1065C110.37 22.1692 113.234 22.3573 116.056 22.5664C118.335 22.7545 120.613 22.9636 122.892 23.1726C134.243 24.3224 145.344 26.392 156.695 29.5696C170.472 33.437 183.893 40.0848 196.75 47.4643C202.498 50.7464 208.519 54.5093 211.069 60.3418C212.386 63.3103 212.616 66.5715 212.7 69.7281C212.846 75.9996 212.575 82.9192 211.195 89.3998C208.247 103.051 200.011 114.569 182.492 114.089C173.001 113.838 163.929 109.991 156.11 104.786C155.253 104.201 154.396 103.615 153.539 103.03C150.8 101.107 148.062 99.1833 145.051 97.6154C141.686 95.9012 138.048 94.7096 134.432 93.6017C119.443 88.9398 104.307 84.9261 89.3395 81.7276C83.7787 80.536 78.1762 79.449 72.9499 77.1703C67.7237 74.8917 62.811 71.2751 60.2188 66.2161C58.4419 62.8086 57.8356 58.9411 57.5847 55.1364C57.2921 50.4537 57.6684 45.5201 60.2188 41.6109V41.59Z" stroke="#2D2926" stroke-width="1.5" stroke-miterlimit="10" />
            <path d="M86.8673 50.5388C89.4595 45.1453 93.0342 42.1558 98.9922 38.2048C102.421 35.9261 106.1 33.8774 110.302 32.6649C114.504 31.4316 119.04 31.327 123.242 31.7869C125.583 32.0378 127.841 32.435 130.036 32.8949C131.813 33.2503 133.59 33.6684 135.325 34.0865C144.063 36.177 152.091 40.0235 161.31 42.4276C171.428 45.0616 181.4 50.8941 191.121 56.6221C195.427 59.1516 199.88 62.0156 202.012 66.2802C203.099 68.4753 203.476 70.8375 203.705 73.158C204.207 77.8198 204.354 82.9415 203.559 87.8124C201.866 98.0977 195.469 106.836 182.675 107.129C175.296 107.296 168.104 104.766 161.77 101.066C161.059 100.648 160.369 100.23 159.679 99.8119C157.464 98.4322 155.248 97.0315 152.864 95.8818C150.209 94.6066 147.366 93.6449 144.544 92.7251C132.879 88.8786 121.256 85.1784 110.009 81.7499C105.828 80.4747 101.647 79.2414 97.7797 77.1927C93.8913 75.144 90.2748 72.1545 88.2261 68.2035C87.6617 67.1373 83.0416 58.629 86.9091 50.6015L86.8673 50.5388Z" stroke="#2D2926" stroke-width="1.5" stroke-miterlimit="10" />
            <path d="M114.529 56.1608C115.114 54.5511 116.013 53.1295 117.017 51.8125C118.354 50.0983 119.86 48.4677 121.427 46.9208C123.581 44.8094 125.964 42.8025 129.037 41.6945C132.089 40.5865 135.81 40.6493 138.988 41.4645C140.744 41.9036 142.374 42.5098 143.963 43.2206C145.238 43.785 146.472 44.3704 147.705 44.9975C153.851 48.0496 159.286 52.0216 165.872 55.241C171.829 58.1677 178.874 61.6588 185.439 65.7562C188.303 67.5331 191.229 69.4982 192.902 72.1949C193.78 73.5956 194.261 75.0798 194.658 76.5641C195.515 79.6371 196.079 82.9401 195.87 86.1804C195.452 93.0791 190.874 99.1415 182.784 100.103C177.537 100.73 172.206 99.476 167.377 97.3019C166.833 97.0719 166.311 96.8211 165.788 96.5493C164.095 95.734 162.422 94.8769 160.645 94.1034C158.68 93.2463 156.652 92.5355 154.625 91.8248C146.263 88.8144 138.172 85.386 130.626 81.7695C127.824 80.4315 125.086 79.0309 122.535 77.2121C120.006 75.3725 117.644 73.052 116.159 70.1881C115.135 68.2439 114.571 66.1116 114.173 63.9793C113.714 61.3661 113.63 58.6067 114.487 56.2026L114.529 56.1608Z" stroke="#2D2926" stroke-width="1.5" stroke-miterlimit="10" />
            <path d="M188.132 84.5091C186.501 77.6523 175.254 72.635 170.384 68.0359C166.495 64.3567 163.589 59.8412 160.056 55.8692C158.489 54.0923 156.837 52.2944 154.726 51.1447C152.614 49.9949 149.897 49.6186 147.764 50.7266C145.841 51.73 144.754 53.7369 143.876 55.6393C142.726 58.106 141.723 60.7401 141.681 63.4368C141.618 66.4681 142.747 69.4157 144.169 72.1124C149.667 82.5649 160.056 90.4879 171.868 93.2474C175.484 94.1045 179.456 94.4599 182.885 93.0384C186.313 91.6168 188.989 88.0002 188.153 84.4882H188.132V84.5091Z" stroke="#2D2926" stroke-width="1.5" stroke-miterlimit="10" />
        </svg>,
        very_short_description: 'skin loses its bounce',
    },
    [AGING_HALLMARKS.COLLAGEN_LOSS]: {
        title: 'Collagen Loss',
        description: 'Collagen is the scaffolding of skin, providing structure and support. Loss of this leads to decreased skin firmness and resilience.',
        short_description: "Collagen provides skin structure and stability but decreases with age, leading to reduced firmness, resilience, and density, resulting in a less youthful appearance. Stressors like sun exposure and harsh skincare products can accelerate this decline, further diminishing skin's youthful qualities.",
        icon: <svg width="230" height="117" viewBox="0 0 230 117" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M154.387 48.3442C126.108 41.317 78.8915 33.7589 47.2582 41.1471C22.2913 46.9855 -5.96637 67.5153 1.10336 93.565C7.53617 117.279 37.0888 123.648 60.7395 108.426C84.3901 93.204 98.9118 72.0373 110.291 58.4923C130.651 34.2048 156.489 14.2482 180.458 5.35267C206.062 -4.17978 225.105 1.42504 229.394 17.3691C234.489 36.2641 207.293 61.5283 154.387 48.3654V48.3442Z" fill="#2D2A26" />
        </svg>,
        very_short_description: 'skin support structure weakness',
    },
    [AGING_HALLMARKS.UNEVEN_TEXTURE]: {
        title: 'Uneven Skin Texture',
        description: 'Uneven texture dulls complexion, diminishes radiance smooth, reflective skin signifies youthfulness and vitality.',
        short_description: "Smooth, even skin texture signifies youthfulness and vitality. Uneven texture can make skin appear dull and aged, lacking radiance and luminosity. Radiant skin looks smooth and reflective, a key characteristic of youthful skin.",
        icon: <svg width="209" height="160" viewBox="0 0 209 160" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M64.5651 80.5463C79.2609 74.2368 89.6447 67.9669 87.8646 77.085C86.0845 86.2031 62.9432 97.2397 57.9589 97.121C52.9747 97.0023 44.628 89.1106 64.5651 80.5661V80.5463Z" stroke="#2D2926" stroke-width="1.83091" stroke-miterlimit="10" />
            <path d="M147.162 54.0225C154.737 48.7019 159.464 56.5344 165.16 55.4466C174.101 53.7654 183.436 46.546 176.949 36.7753C170.422 26.9452 159.306 30.7032 151.098 42.6496C145.836 50.2843 134.167 48.3657 134.009 54.8334C133.87 60.1539 141.584 57.9189 147.162 54.0027V54.0225Z" stroke="#2D2926" stroke-width="1.83091" stroke-miterlimit="10" />
            <path d="M80.7042 67.6701C67.8874 66.9185 35.905 84.2843 44.6671 98.9405C53.4489 113.597 71.7443 100.582 76.8868 96.8835C77.0055 96.8044 110.293 69.4106 80.6844 67.6701H80.7042Z" stroke="#2D2926" stroke-width="1.83091" stroke-miterlimit="10" />
            <path d="M8.01691 117.612C-5.78876 91.2664 3.11173 70.5974 14.6824 55.2094C25.1652 41.2653 37.2699 35.3712 53.9633 32.8197C71.5467 30.1496 86.7963 37.5468 103.885 34.3229C124.871 30.3671 143.918 7.66092 163.341 2.26128C177.127 -1.55605 190.003 2.49865 201.118 25.462C227.899 80.7638 160.394 75.1663 145.579 88.3984C130.745 101.63 145.579 126.572 135.353 134.127C125.108 141.683 103.49 130.092 92.6904 141.762C87.1127 147.775 75.4827 161.402 55.7434 158.633C35.9843 155.864 24.2159 148.467 8.05651 117.612H8.01691Z" stroke="#2D2926" stroke-width="1.83091" stroke-miterlimit="10" />
            <path d="M85.6096 44.5093C97.0616 43.0655 104.222 44.9642 114.269 40.1579C125.8 34.6396 140.931 23.8206 157.585 15.3156C175.01 6.41514 184.662 12.329 193.978 29.5762C205.213 50.4034 192.97 63.0619 179.896 68.7385C168.958 73.4854 156.062 74.5139 148.942 77.9554C136.263 84.0671 139.151 96.9036 130.092 102.719C120.599 108.811 106.714 107.169 98.1297 117.335C92.4334 124.08 83.6318 134.958 69.3712 135.967C55.9413 136.897 43.5004 135.196 29.8925 118.423C18.3812 104.242 18.8361 90.4952 23.3061 75.305C27.2817 61.7763 35.134 54.6163 46.131 49.9683C59.1258 44.4697 73.1292 46.1114 85.6492 44.5489L85.6096 44.5093Z" stroke="#2D2926" stroke-width="1.83091" stroke-miterlimit="10" />
            <path d="M101.59 56.5346C88.793 60.7673 82.3847 53.7854 67.3329 54.6754C54.081 55.4666 25.9357 63.0814 31.8496 95.321C38.0008 128.807 70.0031 120.915 82.9583 113.241C101.649 102.165 108.255 79.9331 124.791 71.2699C138.893 63.8726 159.068 71.4677 175.326 62.8046C183.752 58.3148 194.452 45.142 186.817 33.6702C179.183 22.1985 172.972 14.5243 151.809 28.3497C130.645 42.1554 111.994 53.0931 101.57 56.5544L101.59 56.5346Z" stroke="#2D2926" stroke-width="1.83091" stroke-miterlimit="10" />
        </svg>,
        very_short_description: 'rough or bumpy skin',
    },
    [AGING_HALLMARKS.DYSPIGMENTATION]: {
        title: 'Dyspigmentation',
        short_description: "Even skin tone typifies youthful skin. Uneven tone can highlight age-related imperfections, deepening the appearance of fine lines and wrinkles. Dyspigmentation types like age or sun spots correlate directly with sun exposure and aging. Over time, these spots become more pronounced and visible.",
        description: 'Uneven tone accentuates age-related imperfections, making fine lines and wrinkles appear deeper. Age/sun spots worsen with age and sun exposure.',
        icon: <svg width="176" height="139" viewBox="0 0 176 139" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M94.1871 81.6524C102.214 64.3599 122.374 58.7465 143.654 58.9061C162.747 59.0391 187.387 84.7916 169.947 100.727C150.054 118.898 143.494 147.816 115.254 134.753C88.6405 122.436 86.1605 98.9448 94.1871 81.6524Z" fill="#2D2A26" />
            <path d="M115.867 34.9365C124.08 38.7408 139.387 33.154 143.2 24.96C147.014 16.7661 143.44 7.00249 135.227 3.19815C127.014 -0.606191 112.96 -2.38864 109.147 5.83192C105.334 14.0525 107.654 31.1321 115.894 34.9365H115.867Z" fill="#2D2A26" />
            <path d="M32.9597 24.8535C20.9863 37.9692 22.293 52.3352 32.6663 59.6246C43.0397 66.9141 61.9997 71.5431 69.3063 61.1943C76.613 50.8454 72.773 28.6579 62.3997 21.3418C52.0263 14.0258 41.493 15.489 32.9597 24.8269V24.8535Z" stroke="#2D2A26" stroke-miterlimit="10" />
            <path d="M7.36 73.8313C11.4248 73.8313 14.72 70.5439 14.72 66.4886C14.72 62.4334 11.4248 59.146 7.36 59.146C3.29518 59.146 0 62.4334 0 66.4886C0 70.5439 3.29518 73.8313 7.36 73.8313Z" fill="#2D2A26" />
        </svg>,
        very_short_description: 'dark spots and discoloration',
    },
    [AGING_HALLMARKS.VOLUME_LOSS]: {
        title: 'Volume Loss',
        description: 'Subcutaneous fat loss causes volume deficiency, sagging skin; visible bones, thinning skin, and prominent wrinkles.',
        short_description: "Subcutaneous fat loss causes volume deficiency and sagging skin. With age, diminished fat reduces structural support, unlike collagen. This reveals underlying bone and tissue, creating a skeletal, aged appearance. It also thins the skin, making fine lines and wrinkles more prominent.",
        icon: <svg width="227" height="75" viewBox="0 0 227 75" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M223.062 53.9936C227.109 58.2892 228.349 66.4052 223.062 69.0486C202.121 79.4983 172.321 67.5204 113.443 67.5204C54.5657 67.5204 24.745 81.8939 3.94887 69.6682C-1.75096 66.3226 -0.862938 57.8967 3.94887 53.3741C19.4582 38.856 55.3918 0.402832 113.443 0.402832C171.495 0.402832 209.597 39.6821 223.062 53.9936Z" fill="#2D2926" />
        </svg>,
        very_short_description: 'skin appears sunken or flat',
    },
    [AGING_HALLMARKS.DRYNESS]: {
        title: 'Dryness',
        description: 'Xerosis exacerbates aging signs: inflammation, loss of elasticity, rough texture, dyspigmentation; skin becomes more prone to damage.',
        short_description: "Dry skin can and worsen any of the other hallmarks. It leads to increased inflammation, loss of elasticity, rough skin texture, and dyspigmentation. It makes the skin more prone to damage and enhances appearance of several signs of aging.",
        icon: <svg width="250" height="89" viewBox="0 0 250 89" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 89H250L244.786 58.1037H192.005L178.65 21.5638H117.641L107.476 0H88.3789L7.67841 54.3267L0 88.9781V89Z" fill="#2D2926" /></svg>,
        very_short_description: 'dry and rough skin',
    },
}


export const REPORT_PAGE_CONFIG = {
    [KIT_TYPES.TYPE_A] : {
        // allowed section list for report 
        reportName: 'Skin Health Report',
        welcomeText: 'We are happy to present your Skin Health Report',
        welcomeTextMD03: 'We are happy to present your Skin Health Report, as part of your MD-03 Protocol™',
        scoreSectionParaFirst: 'This section summarizes your important skin health scores, derived from microbial samples and clinical data from three facial areas. Detailed scores for each area follow below.',
        scoreSectionParaSecond: 'Scores for diversity, hydration, sensitivity and antibiotic resistance are measured on a scale of 1-100. Your skin age is compared to your actual age.',
        allowedSections: [REPORT_SECTIONS.DIVERSITY, REPORT_SECTIONS.SKIN_AGE, REPORT_SECTIONS.HYDRATION, REPORT_SECTIONS.SENSITIVITY,REPORT_SECTIONS.ANTIBIOTIC],
        [REPORT_SECTIONS.DIVERSITY]: {
            count: '01',
            title: 'DIVERSITY',
            subTitle: 'YOUR OVERALL SKIN DIVERSITY',
            description: 'Your Diversity Score is based on the microbiome of your t-zone, cheek, and chin. Click on each facial area below for specific details on beneficial and potentially harmful bacteria. Note that higher microbial diversity is generally correlated with healthy skin.'
        },
        [REPORT_SECTIONS.SENSITIVITY]: {
            count: '04',
            title: 'SENSITIVITY ',
            subTitle: 'YOUR SKIN SENSITIVITY LEVEL',
            description: <>Your sensitivity score is a measurement of how reactive your skin is to the environment or external stressors. It can be affected by a multitude of factors, including skin microbiome, daily habits, stress, and genetics, amongst others. It is measured on a scale of 1-100, with 100 being the most sensitive.<br /><br />Below, you will find a sensitivity score calculated for each of your three facial areas.</>
        }, 
        [REPORT_SECTIONS.ANTIBIOTIC]: {
            count: '05',
            title: 'ANTIBIOTIC RESISTANCE',
            subTitle: 'YOUR ANTIBIOTIC RESISTANCE SCORE',
            description: 'Antibiotic resistance is a growing concern. Our Antibiotic Resistance Score is based on the presence and quantity of DNA snippets associated with 51 known antibiotic resistance genes. It is reported on a scale of 1-100 and is calculated based on the relative quantity of resistance genes in your samples compared to our whole cohort.'
        }, 
        [REPORT_SECTIONS.NUTRIENT]: {
            count: '06',
            title: 'NUTRIENT  RECOMMENDATIONS ',
            description: 'Based on your skin microbiome results and clinical data, here are some nutrients that can help optimize your skin health. Also provided are examples of foods that include these nutrients.'
        },
        [REPORT_SECTIONS.SKINCARE]: {
            count: '07',
            title: 'SKINCARE  INGREDIENT RECOMMENDATIONS',
            description: 'Based on your skin microbiome results and clinical data, here are some skincare ingredients that can help optimize your skin health.'
        },
        [REPORT_SECTIONS.PRODUCT]: {
            count: '08',
            title: 'YOUR IDEAL SKIN REGIMEN',
        }  
    },
    [KIT_TYPES.TYPE_X]: {
        // allowed section list for report 
        reportName: 'Skin Health Report',
        welcomeText: 'We are happy to present your Skin Health Report',
        welcomeTextMD03: 'We are happy to present your Skin Health Report, as part of your MD-03 Protocol™',
        scoreSectionParaFirst: 'This section summarizes your important skin health scores, derived from microbial samples and clinical data from three facial areas. Detailed scores for each area follow below.',
        scoreSectionParaSecond: 'Scores for diversity, hydration, sensitivity and antibiotic resistance are measured on a scale of 1-100. Your skin age is compared to your actual age.',
        allowedSections: [REPORT_SECTIONS.DIVERSITY, REPORT_SECTIONS.SKIN_AGE, REPORT_SECTIONS.HYDRATION, REPORT_SECTIONS.SENSITIVITY, REPORT_SECTIONS.ANTIBIOTIC],
        [REPORT_SECTIONS.DIVERSITY]: {
            count: '01',
            title: 'DIVERSITY',
            subTitle: 'YOUR OVERALL SKIN DIVERSITY',
            description: 'Your Diversity Score is based on the microbiome of your t-zone, cheek, and chin. Click on each facial area below for specific details on beneficial and potentially harmful bacteria. Note that higher microbial diversity is generally correlated with healthy skin.'
        },
        [REPORT_SECTIONS.SENSITIVITY]: {
            count: '04',
            title: 'SENSITIVITY ',
            subTitle: 'YOUR SKIN SENSITIVITY LEVEL',
            description: <>Your sensitivity score is a measurement of how reactive your skin is to the environment or external stressors. It can be affected by a multitude of factors, including skin microbiome, daily habits, stress, and genetics, amongst others. It is measured on a scale of 1-100, with 100 being the most sensitive.<br /><br />Below, you will find a sensitivity score calculated for each of your three facial areas.</>
        },
        [REPORT_SECTIONS.ANTIBIOTIC]: {
            count: '05',
            title: 'ANTIBIOTIC RESISTANCE',
            subTitle: 'YOUR ANTIBIOTIC RESISTANCE SCORE',
            description: 'Antibiotic resistance is a growing concern. Our Antibiotic Resistance Score is based on the presence and quantity of DNA snippets associated with 51 known antibiotic resistance genes. It is reported on a scale of 1-100 and is calculated based on the relative quantity of resistance genes in your samples compared to our whole cohort.'
        },
        [REPORT_SECTIONS.NUTRIENT]: {
            count: '06',
            title: 'NUTRIENT  RECOMMENDATIONS ',
            description: 'Based on your skin microbiome results and clinical data, here are some nutrients that can help optimize your skin health. Also provided are examples of foods that include these nutrients.'
        },
        [REPORT_SECTIONS.SKINCARE]: {
            count: '07',
            title: 'SKINCARE  INGREDIENT RECOMMENDATIONS',
            description: 'Based on your skin microbiome results and clinical data, here are some skincare ingredients that can help optimize your skin health.'
        },
        [REPORT_SECTIONS.PRODUCT]: {
            count: '08',
            title: 'YOUR IDEAL SKIN REGIMEN',
        }
    },
    [KIT_TYPES.TYPE_B]: {
        // allowed section list for report 
        reportName: 'Body Blemish Report',
        welcomeText: 'We are happy to present your Body Blemish Report',
        welcomeTextMD03: 'We are happy to present your Body Blemish Report, as part of your MD-03 Protocol™',
        scoreSectionParaFirst: 'This section summarizes your key skin health scores from your microbial sample from you body blemish area.',
        scoreSectionParaSecond: 'Scores for diversity, sensitivity and antibiotic resistance are on a scale of 1-100.',
        allowedSections: [REPORT_SECTIONS.DIVERSITY, REPORT_SECTIONS.SENSITIVITY, REPORT_SECTIONS.ANTIBIOTIC],
        swabKey: "BB",
        [REPORT_SECTIONS.DIVERSITY]: {
            count: '01',
            title: 'DIVERSITY',
            subTitle: 'YOUR SKIN DIVERSITY',
            description: "Your Diversity Score reflects the microbiome composition of your body blemish area. Below, you'll find a breakdown of beneficial and potentially harmful bacteria in this region. Keep in mind that higher microbial diversity is typically associated with healthier skin."
        },
        [REPORT_SECTIONS.SENSITIVITY]: {
            count: '02',
            title: 'SENSITIVITY ',
            subTitle: 'YOUR SKIN SENSITIVITY LEVEL',
            description: 'Your sensitivity score measures how reactive your skin is to environmental and external stressors. Factors like your microbiome, habits, stress, and genetics influence this score, which ranges from 1-100, with 100 indicating the highest sensitivity.'        
        },
        [REPORT_SECTIONS.ANTIBIOTIC]: {
            count: '03',
            title: 'ANTIBIOTIC RESISTANCE',
            subTitle: 'YOUR ANTIBIOTIC RESISTANCE SCORE',
            description: 'Antibiotic resistance is a growing concern. Our Antibiotic Resistance Score is based on the presence and quantity of DNA snippets associated with 51 known antibiotic resistance genes. It is reported on a scale of 1-100 and is calculated based on the relative quantity of resistance genes in your samples compared to our whole cohort.'
        },
        [REPORT_SECTIONS.NUTRIENT]: {
            count: '04',
            title: 'NUTRIENT  RECOMMENDATIONS ',
            description: 'Based on your body blemish test results and clinical data, here are some nutrients that can help optimize your skin health. Also provided are examples of foods that include these nutrients.'
                },
        [REPORT_SECTIONS.SKINCARE]: {
            count: '05',
            title: 'SKINCARE  INGREDIENT RECOMMENDATIONS',
            description: 'Based on your body blemish test results and clinical data, here are some skincare ingredients that can help optimize your skin health.'
        },
        [REPORT_SECTIONS.PRODUCT]: {
            count: '06',
            title: 'YOUR IDEAL SKIN REGIMEN',
        }
    },
    [KIT_TYPES.TYPE_C]: {
        // allowed section list for report
        reportName: 'Odor Discovery Report',
        welcomeText: 'We are happy to present your Odor Discovery Report',
        welcomeTextMD03: 'We are happy to present your Odor Discovery Report, as part of your MD-03 Protocol™',
        scoreSectionParaFirst: 'This section summarizes your key skin health scores from your microbial sample from you odor problem area.',
        scoreSectionParaSecond: 'Scores for diversity, sensitivity and antibiotic resistance are on a scale of 1-100.',
        allowedSections: [REPORT_SECTIONS.DIVERSITY, REPORT_SECTIONS.SENSITIVITY, REPORT_SECTIONS.ANTIBIOTIC],
        swabKey: "AP",
        [REPORT_SECTIONS.DIVERSITY]: {
            count: '01',
            title: 'DIVERSITY',
            subTitle: 'YOUR SKIN DIVERSITY',
            description: 'Your Diversity Score is based on the microbiome of your odor problem area. See below for your beneficial and potentially harmful bacteria. Note that higher microbial diversity is generally correlated with healthy skin.'
        },
        [REPORT_SECTIONS.SENSITIVITY]: {
            count: '02',
            title: 'SENSITIVITY ',
            subTitle: 'YOUR SKIN SENSITIVITY LEVEL',
            description: 'Your sensitivity score measures how reactive your skin is to environmental and external stressors. Factors like your microbiome, habits, stress, and genetics influence this score, which ranges from 1-100, with 100 indicating the highest sensitivity.'
        },
        [REPORT_SECTIONS.ANTIBIOTIC]: {
            count: '03',
            title: 'ANTIBIOTIC RESISTANCE',
            subTitle: 'YOUR ANTIBIOTIC RESISTANCE SCORE',
            description: 'Antibiotic resistance is a growing concern. Our Antibiotic Resistance Score is based on the presence and quantity of DNA snippets associated with 51 known antibiotic resistance genes. It is reported on a scale of 1-100 and is calculated based on the relative quantity of resistance genes in your samples compared to our whole cohort.'
        },
        [REPORT_SECTIONS.NUTRIENT]: {
            count: '04',
            title: 'NUTRIENT RECOMMENDATIONS ',
            description: 'Based on your odor microbiome results and clinical data, here are some nutrients that can help optimize your odor health. Also provided are examples of foods that include these nutrients.'
        },
        [REPORT_SECTIONS.SKINCARE]: {
            count: '05',
            title: 'SKINCARE INGREDIENT RECOMMENDATIONS',
            description: 'Based on your odor microbiome results and clinical data, here are some skincare ingredients that can help optimize your odor health.'
        },
        [REPORT_SECTIONS.PRODUCT]: {
            count: '06',
            title: 'YOUR IDEAL SKIN REGIMEN',
        }
    },
    [KIT_TYPES.TYPE_D]: {
        // allowed section list for report
        reportName: 'Scalp Health Report.',
        welcomeText: 'We are happy to present your Scalp Health Report',
        welcomeTextMD03: 'We are happy to present your Scalp Health Report, as part of your MD-03 Protocol™',
        scoreSectionParaFirst: 'This section summarizes your key scalp health scores from your microbial sample from your scalp.',
        scoreSectionParaSecond: 'Scores for diversity, sensitivity, and antibiotic resistance are on a scale of 1-100.',
        allowedSections: [REPORT_SECTIONS.DIVERSITY, REPORT_SECTIONS.SENSITIVITY, REPORT_SECTIONS.ANTIBIOTIC],
        swabKey: "SL",
        [REPORT_SECTIONS.DIVERSITY]: {
            count: '01',
            title: 'DIVERSITY',
            subTitle: 'YOUR SCALP SKIN DIVERSITY',
            description: 'Your Diversity Score is based on the microbiome of your scalp. See below for your beneficial and potentially harmful bacteria. Note that higher microbial diversity is generally correlated with better scalp health.'
        },
        [REPORT_SECTIONS.SENSITIVITY]: {
            count: '02',
            title: 'SENSITIVITY ',
            subTitle: 'YOUR SCALP SENSITIVITY LEVEL',
            description: 'Your sensitivity score measures how reactive your scalp is to environmental and external stressors. Factors like your microbiome, habits, stress, and genetics influence this score, which ranges from 1-100, with 100 indicating the highest sensitivity.'
        },
        [REPORT_SECTIONS.ANTIBIOTIC]: {
            count: '03',
            title: 'ANTIBIOTIC RESISTANCE',
            subTitle: 'YOUR ANTIBIOTIC RESISTANCE SCORE',
            description: 'Antibiotic resistance is a growing concern. Our Antibiotic Resistance Score is based on the presence and quantity of DNA snippets associated with 51 known antibiotic resistance genes. It is reported on a scale of 1-100 and is calculated based on the relative quantity of resistance genes in your samples compared to our whole cohort.'
        },
        [REPORT_SECTIONS.NUTRIENT]: {
            count: '04',
            title: 'NUTRIENT RECOMMENDATIONS',
            description: 'Based on your scalp health microbiome results and clinical data, here are some nutrients that can help optimize your scalp health. Also provided are examples of foods that include these nutrients.'
        },
        [REPORT_SECTIONS.SKINCARE]: {
            count: '05',
            title: 'SKINCARE INGREDIENT RECOMMENDATIONS',
            description: 'Based on your scalp health microbiome results and clinical data, here are some skincare ingredients that can help optimize your scalp health.'
        },
        [REPORT_SECTIONS.PRODUCT]: {
            count: '06',
            title: 'YOUR IDEAL SKIN REGIMEN',
        }
    }
}