const KIT_A = "A"
const KIT_B = "B"
const KIT_C = "C"
const KIT_E = "E"
const KIT_X = "X"
const KIT_D = "D"

export const KIT_TYPES ={
    TYPE_A: KIT_A,
    TYPE_B: KIT_B,
    TYPE_C: KIT_C,
    TYPE_E: KIT_E,
    TYPE_X: KIT_X,
    TYPE_D: KIT_D
}

export const KIT_REPORT_TITLES = {
    [KIT_A]: "Skin Health Report",
    [KIT_X]: "Skin Health Report",
    [KIT_B]: "Body Blemish Discovery Report",
    [KIT_C]: "Odor Discovery Report",
    [KIT_D]: "Scalp Health Report",
    [KIT_E]: "Skin discovery report (Abbreviated)"
}

const sampleIntroBlock_1 = <>Below, you will find an assessment of your skin microbiome type, along with
                                other key pieces of information like your <span>skin age</span>, <span>hydration level</span>, <span>microbial diversity</span>,
                                as well as <span>specific recommendations</span> for skin care and food ingredients based
                                on the microbial ecosystem identified in your skin swab samples.</>;

const sampleIntroBlock_2 = <>After conducting the largest, most diverse study on the skin microbiome, Parallel has
                                defined <span>eight distinct skin microbiome types</span>, matched
                                with <span>eight distinct precision phage-based serums</span>.</>;

const sampleIntroBlock_3 = <>Below, you will find an abbreviated version of your skin microbiome discovery report.
                                It includes your good & bad bacteria and food & skincare recommendations based on the
                                microbial ecosystem on your skin. Typically we swab three areas (+ control) of your skin
                                for our full report. Our full Skin Microbiome Discovery Report includes
                                your <span>skin age</span>, <span>hydration level</span>, <span>microbial diversity</span>, <span>along with a full set of skin care and food ingredient recommendations</span>.
                                It also identifies your Skin Microbiome Type™ and recommended Custom Active Phage Serum.</>;
  
const sampleIntroBlock_4 = <>{sampleIntroBlock_2} One's Skin Microbiome Type™ may change over time due to environmental
                                factors. One microbiome type is not better or worse than another; they merely show different patterns.
                                Each recommended serum is meant to support resilience, clarity, and better aging.</>;

const sampleIntroBlock_5 = <>
                                Below, you will find an abbreviated version of your skin microbiome discovery report.
                                It includes your good & bad bacteria and food & skincare recommendations based on the microbial ecosystem on your skin.
                                Typically we swab three areas (+ control) of your skin for our full report. Our full Skin Microbiome Discovery Report includes your 
                                <span> skin age,hydration level, microbial diversity, along with a full set of skin care and food ingredient recommendations.</span>
                                It also identifies your Skin Microbiome Type™ and recommended Custom Active Phage Serum.
                                </>

const sampleIntroBlock_6 = <>
                                After conducting the largest, most diverse study on the skin microbiome,
                                Parallel has defined <span>eight distinct skin microbiome types,</span> matched with <span>eight precision phage-based serums.</span>
                                One's Skin Microbiome Type™ may change over time due to environmental factors.
                                One microbiome type is not better or worse than another; they merely show different patterns. 
                                Each recommended serum is meant to support resilience, clarity, and better aging.
                              </>
const sampleIntroBlock_7 = 'Below, you will find information gathered from your Body Blemish Microbiome Discovery Kit. You will find key pieces of information, including your top "good" and "bad" bacteria and microbial diversity, along with specific recommendations for food and skin care ingredients.'
const sampleIntroBlock_8 = 'Based on the information in your report, we recommend primary and secondary Custom Active Phage serums to help clear your body blemish problem area by balancing your good and bad microbes.'

const sampleIntroBlock_21 = `Your skin microbiome type is based on your swab samples at
                            the time you conducted the test. One microbiome type is not necessarily better or worse
                            than another; they merely show different patterns. Note that these patterns may change
                            over time based on your lifestyle, diet, and environment, amongst other factors.`

const sampleIntroBlock_22 = `Your recommended serum contains the most appropriate phage
                            cocktail for your skin microbiome type and is meant to support resilience, clarity, and
                            better aging.`;

const sampleProductGetStartedTextCol_1 = "Based on your report, we've come up with a personalized skin health game plan for you. These recommendations are based in order of importance and priority.";
const sampleProductGetStartedTextCol_2 =  <>
                                        If you have any questions, don't hesitate to reach out:
                                        <br/>
                                        <a href='mailto:questions@parallelhealth.io' target='_blank' rel="noreferrer"
                                        style={{color: '#2d2a26'}}>
                                        <b>questions@parallelhealth.io</b>
                                        </a>
                                        </>;

 const sampleProductGetStartedTextCol_3 = "First, order the Skin Microbiome Discovery Kit Bundle to identify your Skin Microbiome Type™, and then receive a targeted Custom Active Phage serum. Second, we highly recommend our Holy Calming Cleanser and Skin Barrier Silk Cream as part of our Daily Reset Bundle, which work synergistically with our phage serums to promote healthy aging. Lastly, we recommend Parallel's Concierge Care service, which comes with 24/7 access to clinical guidance and skin advice.";
 const sampleProductGetStartedTextCol_4 =  "As your skin microbiome type is expected to change at least twice a year, it is advised to take the Skin Microbiome Discovery Test every 3-6 months so that recommendations can reflect where you are in your skin health journey.";

const sampleGoodBacteriaDesc_1 = "We all have hundreds, even thousands, of microbes living in our skin microbiome. Here you can find your top “good“ microbes listed as a percentage relative to each other. This list can change, and usually does 2-4 times a year due to a variety of factors like climate, stress level, diet, hormone changes, and household members.";
const sampleGoodBacteriaDesc_2 = "These microbes usually serve important functions, such as protecting your skin barrier, ensuring appropriate sebum production, contributing to microbial diversity, and limiting the growth of other bacteria.";

const sampleBadBacteriaDesc_1 = "In addition to “good“ microbes, we all have our fair share of “bad microbes,“ as well. Here you can find your top “bad” microbes listed as a percentage relative to each other. As with the “good” microbes, this list is expected to change at least twice a year because of the environment or lifestyle factors as discussed above.";
const sampleBadBacteriaDesc_2 = "Overgrowth of these microbes can cause skin issues, such as breakouts, blemishes, discoloration, etc., so it is important to be cognizant of what baddies are growing in your skin microbiome. But not to worry -- at low quantities, these bad bacteria may not pose problems or have clinical significance. Notably, this is not a diagnostic result and does not indicate your health status.";


const sampleGoodBacteriaDesc_3 = "We all have thousands of microbes living in our skin microbiome. Here, you can find your top “good'' microbes listed as a percentage relative to each other. This list can change 2-4 times a year due to factors like climate, stress level, diet, hormone changes, and household members.";
const sampleGoodBacteriaDesc_4 = "These microbes serve important functions, such as protecting your skin barrier, ensuring appropriate sebum production, and limiting the growth of other bacteria.";

const sampleBadBacteriaDesc_3 = "We all have our fair share of “bad microbes,“ as well. Here you can find your top “bad” microbes listed as a percentage relative to each other. This list is also expected to change at least twice a year due to environmental factors listed above.";
const sampleBadBacteriaDesc_4 = "Overgrowth of these microbes can cause skin issues, such as breakouts, discoloration, and accelerated aging. But not to worry -- at low quantities, these bad bacteria may not pose problems or have clinical significance. Notably, this is not a diagnostic result and does not indicate your health status.";
    
  
export const KIT_REPORTS_CONTENT = {
    [KIT_X]: {
        introBlockLeft_1: sampleIntroBlock_1,
        introBlockLeft_2: sampleIntroBlock_2,
        introBlockRight_1: sampleIntroBlock_21,
        introBlockRight_2: sampleIntroBlock_22,
        getStartedTextCol1: sampleProductGetStartedTextCol_1,
        getStartedTextCol2: sampleProductGetStartedTextCol_2,
        goodBacteriaDescCol1: sampleGoodBacteriaDesc_1,
        goodBacteriaDescCol2: sampleGoodBacteriaDesc_2,
        badBacteriaDescCol1: sampleBadBacteriaDesc_1,
        badBacteriaDescCol2: sampleBadBacteriaDesc_2,
    },
    [KIT_E]: {
        introBlockLeft_1: sampleIntroBlock_3,
        introBlockLeft_2: '',
        introBlockRight_1: sampleIntroBlock_4,
        introBlockRight_2: '',
        getStartedTextCol1: sampleProductGetStartedTextCol_3,
        getStartedTextCol2: sampleProductGetStartedTextCol_4,
        goodBacteriaDescCol1: sampleGoodBacteriaDesc_3,
        goodBacteriaDescCol2: sampleGoodBacteriaDesc_4,
        badBacteriaDescCol1: sampleBadBacteriaDesc_3,
        badBacteriaDescCol2: sampleBadBacteriaDesc_4,
    },
    [KIT_A]: {
        introBlockLeft_1: sampleIntroBlock_1,
        introBlockLeft_2: sampleIntroBlock_2,
        introBlockRight_1: sampleIntroBlock_21,
        introBlockRight_2: sampleIntroBlock_22,
        getStartedTextCol1: sampleProductGetStartedTextCol_1,
        getStartedTextCol2: sampleProductGetStartedTextCol_2,
        goodBacteriaDescCol1: sampleGoodBacteriaDesc_1,
        goodBacteriaDescCol2: sampleGoodBacteriaDesc_2,
        badBacteriaDescCol1: sampleBadBacteriaDesc_1,
        badBacteriaDescCol2: sampleBadBacteriaDesc_2,
    },
    [KIT_B]: {
        introBlockLeft_1: sampleIntroBlock_7,
        introBlockLeft_2: '',
        introBlockRight_1: sampleIntroBlock_8,
        introBlockRight_2: '',
        getStartedTextCol1: sampleProductGetStartedTextCol_1,
        getStartedTextCol2: sampleProductGetStartedTextCol_2,
        goodBacteriaDescCol1: sampleGoodBacteriaDesc_1,
        goodBacteriaDescCol2: sampleGoodBacteriaDesc_2,
        badBacteriaDescCol1: sampleBadBacteriaDesc_1,
        badBacteriaDescCol2: sampleBadBacteriaDesc_2,
    },
    [KIT_C]: {
        introBlockLeft_1: sampleIntroBlock_5,
        introBlockLeft_2: '',
        introBlockRight_1: sampleIntroBlock_6,
        introBlockRight_2: '',
        getStartedTextCol1: sampleProductGetStartedTextCol_3,
        getStartedTextCol2: sampleProductGetStartedTextCol_4,
        goodBacteriaDescCol1: sampleGoodBacteriaDesc_3,
        goodBacteriaDescCol2: sampleGoodBacteriaDesc_4,
        badBacteriaDescCol1: sampleBadBacteriaDesc_3,
        badBacteriaDescCol2: sampleBadBacteriaDesc_4,
    },
    [KIT_D]: {
        introBlockLeft_1: sampleIntroBlock_5,
        introBlockLeft_2: '',
        introBlockRight_1: sampleIntroBlock_6,
        introBlockRight_2: '',
        getStartedTextCol1: sampleProductGetStartedTextCol_3,
        getStartedTextCol2: sampleProductGetStartedTextCol_4,
        goodBacteriaDescCol1: sampleGoodBacteriaDesc_3,
        goodBacteriaDescCol2: sampleGoodBacteriaDesc_4,
        badBacteriaDescCol1: sampleBadBacteriaDesc_3,
        badBacteriaDescCol2: sampleBadBacteriaDesc_4,
    }
    
}