import React from 'react'
import style from './ReportSections.module.scss'
import { Row, Col } from 'antd';
import PHHex from '../../Global/PHHex/PHHex'
import PHFaceRangeSlider from '../../Global/PHFaceRangeSlider/PHFaceRangeSlider'
import { face_range_slider_2, face_range_slider_2_mobile, clinical_doctor } from '@ph/common/assets'
import DescriptionContainer from '../ReportContainer/DescriptionContainer';
import ReportSingleProduct from '../ReportContainer/ReportSingleProduct';
import ReportClinicalProduct from '../ReportContainer/ReportClinicalProduct';

const PRODUCT = {
    product_id: 89238092,
    name: '1 : 1  Clinical Consult',
    description: {
        short_text: 'Book your complimentary consult with a member of our clinical team.'
    },
    price: null,
    image_url: clinical_doctor
}

function AntibioticSection({ reportData }) {

    const actionButton = {
        text: 'BOOK YOUR CONSULTANT',
        colSpan: 6,
        onClick: () => window.open('https://calendly.com/natalise-parallelhealth/15-min-skin-health-consult', "_blank")
    }
    return (
        <>
            <Row>
                <Col xs={24} xl={16}>
                    <PHFaceRangeSlider
                        rangeMax={'105'}
                        rangeMin={'-5'}
                        foreheadValue={reportData.antibiotic_resistance && Number(reportData.antibiotic_resistance) !== -1 ? Math.round(Number(reportData.antibiotic_resistance)) : null}
                        color={"#2E5ACC"}
                        endColor={"#C46A5A"}
                        domId={'antibiotic'}
                        customLabel={"YOU"}
                        backgroundSrc={face_range_slider_2}
                        mobileScale={1.6}
                        threshold={false}
                        smallContainer={true}
                        mobileBackgroundSrc={face_range_slider_2_mobile}
                    />
                </Col>
                <Col xs={24} xl={8}>
                    <DescriptionContainer
                        title='what does this mean for me?'
                        description={<>Your Antibiotic Resistance Score shows the presence and amount of DNA linked to antibiotic-resistant genes. A higher score reflects a greater number of resistant genes across various antibiotics. This score is for educational purposes only and not a diagnostic tool. More research is needed to determine the risk of antibiotic-resistant infections.<br /><br />Questions? Schedule a chat with Parallel’s MD team.</>}
                        descriptionClass={style.onlyDescription}
                    />
                </Col>
            </Row>
            <ReportClinicalProduct product={PRODUCT} actionButton={actionButton} allowSelect={false} compact={true} />

        </>
    )
}

export default AntibioticSection