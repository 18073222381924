import { useEffect, useState } from 'react';
import { Button, Modal, Typography, Row, Form, Input } from "antd";
import style from '../../AdminLabPortal/ValidateKitFormContent/ValidateKitFormContent.module.scss';
import { postAssignKitcode, postAssignKitOrder, updateRegistration } from '../ServiceDashboardAPIService';
import { getFormDataPayload, handleErrorMsg, handleSuccessMsg } from '@ph/common/global/Helpers';
const { Text } = Typography;

function UpdateKitOrder({
    open,
    closeModal,
    selectedRecord,
    onCallback,
    cancelBtnAction = null,
    proceedBtnClass = '',
    modalTitle = '',
    closable = false,
}) {

    const [orderName, setOrderName] = useState("")
    const [isExistingOrder, setExistingOrder] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)

    const resetForm = () => {
        setOrderName('')
    }

    const handleSuccess = () => {
        resetForm()
        closeModal()
        onCallback()
    }

    const onAssign = () => {
        if (selectedRecord && orderName) {
            setSaveLoading(true)
            const requestBody = {
                kitcode: selectedRecord.kitcode,
                order_name: orderName,
                kit_type_id: selectedRecord.kit_type_id,
            }
            const payload = getFormDataPayload(requestBody)

            postAssignKitOrder(payload)
                .then(res => {
                    if (res?.data) {
                        handleSuccessMsg("Order is assigned successfully!")
                        handleSuccess()
                    }
                })
                .catch(error => {
                    handleErrorMsg(error, "Unable to assign order!")
                }).finally(() => setSaveLoading(false))
        }
    }


    const handleCloseModal = () => {
        resetForm()
        closeModal()
    }

    useEffect(() => {
        if (selectedRecord?.shopify_order_name) {
            setOrderName(selectedRecord?.shopify_order_name)
            setExistingOrder(true)
        } else {
            setOrderName("")
            setExistingOrder(false)
        }
    }, [selectedRecord, open])

    return (
        <Modal open={open}
            onCancel={closeModal}
            afterClose={closeModal}
            closeIcon={''} closable={closable} maskClosable={false}
            footer={''}
            style={{ top: '30%' }}
        >
            <div className='text-center' style={{ padding: '24px' }}>
                <Text className="body-txt-lg"> {modalTitle} </Text>
            </div>
            <Form>
                <Row type="flex" justify="center" align="middle">
                    <CustomInput label={"Order Name"} value={orderName} onChange={(e) => setOrderName(e.target.value)} />
                </Row>
            </Form>

            <div className={style.confirmationActionBtnContainer}>
                <Button className={`ph-btn transparent`}
                    onClick={handleCloseModal} >
                    <Text className={`text-dark`}>NO, GO BACK</Text>
                </Button>
                <Button className={`ph-btn ${proceedBtnClass}`} type='primary'
                    loading={saveLoading} disabled={!orderName || !orderName.trim} onClick={onAssign}>
                    <Text className={`text-light`}>{isExistingOrder ? "CHANGE ORDER" : "ASSIGN ORDER"}</Text>
                </Button>
            </div>
        </Modal>
    );
}

export default UpdateKitOrder;

const CustomInput = ({ label, value, onChange, onVerify, disabled = false, error = false }) => {
    return (
        <Row type="flex" justify="left" align="middle" style={{ width: '94%', marginBottom: '24px' }} className={style.datepickerContainer}>
            <div style={{ minWidth: '140px' }}>
                {!error && <Text className="body-txt-lg">{label}</Text>}
            </div>
            <div style={{ width: '50%' }}>
                {!error ? (<Input
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                />) : (
                    <Text className="body-txt-md text-error" >
                        {error}
                    </Text>
                )}
            </div>
            {!error && (
                <div style={{ minWidth: '40px', marginLeft: '12px' }}>
                    {onVerify && (
                        <Text className="body-txt-lg td-underline hand-cursor" onClick={onVerify} >
                            Verify
                        </Text>
                    )}
                </div>
            )}
        </Row>
    )
}