import React from 'react'
import style from './ReportContainer.module.scss'
import { close_icon_dark } from '@ph/common/assets/index'


function DescriptionContainer({
    title = '', subTitle = '', description = '', containerClassnames = '', descriptionClass = '', color = '#FFFFFF', titleBorderColor = '2D2926', handleClose = null
}) {
    const styles = {
        '--base-color': color,
        '--title-border': titleBorderColor
    }
    return (
        <div className={`${style.descriptionContainer} ${containerClassnames}`} style={styles}>
            {title && (<div className={style.title}>{title}</div>)}
            <div className={`${style.description} ${descriptionClass}`}>
                {subTitle && <div className={style.subTitle}>{subTitle}</div>}
                {description}
                {handleClose && (
                    <div className={style.closeIcon}>
                        <img src={close_icon_dark} alt='x' onClick={handleClose} />
                    </div>
                )}

            </div>

        </div>
    )
}

export default DescriptionContainer