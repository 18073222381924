import connectionInstance from "@ph/admin/src/api_services/connection";

const { REACT_APP_API_URL, REACT_APP_SHOPIFY_ORDERS_URL } = process.env;

const encodeGetParams = params => Object.entries(params).map(kv => kv.map(encodeURIComponent).join("=")).join("&");

export const getKitTypes = () => connectionInstance.get(`${REACT_APP_API_URL}/kit/types`);
export const generateKitCodes = (data) => connectionInstance.post(`${REACT_APP_API_URL}/codes/kitcodes`, data)


function getCommonParamsForListAPIs(params, { kitCodeFilter,startAndEndDate, offset, limit, sortOrder, sortBy, kitType, kitStatus }) {
    if (startAndEndDate && startAndEndDate.hasOwnProperty('startDate') &&
        startAndEndDate.hasOwnProperty('endDate')) {
        params.start_date = startAndEndDate.startDate;
        params.end_date = startAndEndDate.endDate;
    }
    if (typeof offset === 'number') {
        params.offset = offset;
    }
    if (typeof limit === 'number') {
        params.limit = limit;
    }
    if (sortOrder) {
        params.sort_order = sortOrder;

        if (sortBy) {
            params.sort_by = sortBy;
        }
    }
    if (kitType) {
        params.kit_type_id = kitType
    }
    if (kitStatus) {
        params.status = kitStatus
    }
    if( kitCodeFilter) {
        params.search_string = kitCodeFilter
    }
    return params;
}
export function getUnassignedOrders(orderType, { ...commonParams }) {
    if (!orderType) {
        orderType = 'unassigned';
    }
    let getListForKitCode = 'all', params = {};
    params = getCommonParamsForListAPIs(params, commonParams);
    return connectionInstance.get(
        `${REACT_APP_API_URL}/order/${getListForKitCode}/${orderType}`,
        { params }
    ).then(result => {
        result.data.unassigned_kit_orders = result.data.unassigned_kit_orders.map((item, i) => {
            return { ...item, key: '' + i };
        });
        return result;
    });
}

export function getOrderedKits(orderType, { ...commonParams }) {
    if (!orderType) {
        orderType = 'inventory';
    }
    let getListForKitCode = 'ALL', params = {};
    params = getCommonParamsForListAPIs(params, commonParams);
    return connectionInstance.get(
        `${REACT_APP_API_URL}/kit/${getListForKitCode}/${orderType}`,
        { params }
    ).then(result => {
        result.data.kit_code_inventorty_list = result.data.kit_code_inventorty_list.map((item, i) => {
            return { ...item, key: '' + i };
        });
        return result;
    });
}

export function getOrderUrl(id) {
    return `${REACT_APP_SHOPIFY_ORDERS_URL}/${id}`
}


export function getServiceDashboardRecordsCount() {
    return connectionInstance.get(
        `${REACT_APP_API_URL}/customer_service/table_record_count`
    );
}

export function getAllUnassignedKitcode() {
    return connectionInstance.get(
        `${REACT_APP_API_URL}/kit/ALL/unassigned`
    );
}

export function postCommentOnKit(kitcode,data) {
    return connectionInstance.patch(`${REACT_APP_API_URL}/kit/${kitcode}/inventory`, data)
}

export function postAssignKitcode(data) {
    return connectionInstance.patch(`${REACT_APP_API_URL}/order/assign_kitcode`, data)
}

export function postAssignKitOrder(data) {
    return connectionInstance.patch(`${REACT_APP_API_URL}/order/assign_order`, data)
}

export function unassignKitcode(kitcode) {
    return connectionInstance.delete(`${REACT_APP_API_URL}/order/assign_kitcode?kitcode=${kitcode}`)
}

export const updateRegistration = (kitcode, data) => connectionInstance.patch(`${REACT_APP_API_URL}/kit/${kitcode}/update_registration`, data)
