import React, { useEffect, useState } from 'react'
import { Button, Col, Divider, Row, message, Steps, Typography, Space, Grid, Tabs } from 'antd';
import style from './KitUserGuide.module.scss';
import {ExclamationCircleFilled,ArrowRightOutlined} from "@ant-design/icons";
import { list_icon } from "@ph/common/assets";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { KIT_TYPES } from '@ph/common/constants/KitConstants';
import { KIT_INSTRUCTIONS } from '@ph/common/constants/InstructionConstants';
import { HEALTH_PROFILE_STATUS } from '@ph/common/global/Constants';
import { getHealthProfileStatus } from '@ph/subject/src/api_services/api';
import { KIT_INSTRUCTION_TAB_TITLES } from '@ph/common/constants/InstructionConstants';
import { button_arrow } from '@ph/common/assets/index';
import KitRegisterationStatus from './KitRegisterationStatus';
import { isValidKitcode } from '@ph/common/global/Helpers';
import { KIT_REGISTER_PRARAM } from '@ph/common/global/Constants';
import PhTabs from '../Global/PhTabs/PhTabs';


const { useBreakpoint } = Grid;
const { Text, Link } = Typography;
const { Step } = Steps;


const KitUserGuide = (props) => {
    const navigate = useNavigate();
    const { kitId } = useParams();
    const location = useLocation();
    const [kitType,setKitType] = useState(kitId ? kitId[0] : KIT_TYPES.TYPE_A)
    const [showHealthProfile,setShowHealthProfile] = useState(false)
    const screens = useBreakpoint();
    const isTabOrLower = screens.md
    const isMobile = screens.xs

    const queryParams = new URLSearchParams(location.search);
    const myParam = queryParams.get(KIT_REGISTER_PRARAM);
    const isValidKit = isValidKitcode(kitId) && myParam

    function healthProfileResponseHandler(res) {
        if (!res || !res.data) {
            message.error("Unable to fetch Response from server!");
            return;
        }
        if (res.data.status !== HEALTH_PROFILE_STATUS.COMPLETED && res.data.status !== HEALTH_PROFILE_STATUS.NOT_REQUIRED) {
            setShowHealthProfile(true)
        }
        setValid(true)
    }

    useEffect(() => {
        if (kitId) {
            getHealthProfileStatus(kitId)
                .then(healthProfileResponseHandler)
                .catch(() => message.error("Kitcode is Invalid!"))
        }
    }, [kitId])

    useState(()=> {
        const allowedKits = [KIT_TYPES.TYPE_A, KIT_TYPES.TYPE_B, KIT_TYPES.TYPE_C, KIT_TYPES.TYPE_X, KIT_TYPES.TYPE_D]
        if(!allowedKits.includes(kitType)){
            // If kit type is not A,B,C or X
            // Got back to kit user guide
            window.location.href = `${process.env.REACT_APP_REDIRECT_URI}/kit-user-guide`
        }
    },[kitType])

    function healthProfileResponseHandler(res) {
        if(!res || !res.data) {
            message.error("Unable to fetch Response from server!");
            return ;
        }
        if(res.data.status !== HEALTH_PROFILE_STATUS.COMPLETED && res.data.status !== HEALTH_PROFILE_STATUS.NOT_REQUIRED ){
            setShowHealthProfile(true)           
        }
    }

    useEffect(()=> {
        if(kitId){
            getHealthProfileStatus(kitId)
            .then(healthProfileResponseHandler)
            .catch(() => message.error("Something went wrong in Fetching Health profile!"))
        }
    },[kitId])

    useEffect(() => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);
    }, []);

    const tile = (question, title, subtitle) => (
        <Row className={style.tile}>
            <Col xs={24} sm={24} md={24} xl={11} className={style.tileQuestion}>
                {/* <div className={style.tileQuestion}> */}
                <Text className={style.questionText}>{question}</Text>
                {/* </div> */}
            </Col>
            <Col xs={24} sm={24} md={24} xl={0}>
                {/* <div className={style.tileDivider}> */}
                <Divider style={{ background: '#fbeee4', margin: 0 }} type="horizontal" />
                {/* </div> */}
            </Col>
            <Col xs={0} sm={0} md={0} xl={2}>
                <Divider style={{ background: '#fbeee4', height: '100%' }} type="vertical" />
            </Col>
            <Col xs={24} sm={24} md={24} xl={11} className={style.tileTitleContainer}>
                <div className={style.titleSection}>
                    <Text className={style.tileTitle}>{title}</Text>
                    <Text className={style.tileSubtitle}>{subtitle}</Text>
                </div>
            </Col>
        </Row>
    );

    const kitItems = (image, text) => (
        <div className={style.kitItemContainer}>
            <img src={image} className={style.itemImage} alt="kit" />
            <div className={style.textContainer}><Text className={style.itemDesc}>{text}</Text></div>
        </div>
    );

    const instructionBlock = (step, title, image, description) => (
        <div className={style.instructionBlock}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                <span className={style.step}><Text className={`note-1 .font-Mono-medium`} style={{ color: '#fff' }}>STEP {step}</Text></span>
                <Text className={`body-txt-lg medium-text`}>{title}</Text>
            </div>
            <div className={style.instructionDesc}>
                <img src={image} className={style.image} alt={title}></img>
                <div>{description}</div>
                {/* <Row>
                    <Col xs={24} sm={24} md={3} xl={3}>
                        <img src={image} className={style.image} alt="T_ZONE"></img>
                    </Col>
                    <Col xs={24} sm={24} md={21} xl={21}>
                        <Text className={style.description}>
                            {description}
                        </Text>
                    </Col>
                </Row> */}
            </div>
        </div>
    );

    const renderInstructionTiles = KIT_INSTRUCTIONS[kitType]?.TILES.map((item,index) => {
        const evenTile = index % 2 === 0
        return (
            <>
                <Col xs={24} sm={24} md={12} xl={12}>
                    <div className={`${style.tileContainerLeft} ${evenTile ? "" : style.tileContainerRight}`}>{tile(item.question, item.title, item.subtitle)}</div>
                </Col>
                <Col xs={24} sm={24} md={evenTile ? 0 : 24} xl={evenTile ? 0 : 24}>
                    <div style={{ background: 'inherit', height: '20px' }}></div>
                </Col>
            </>
        )
    })

    const renderKitContent = KIT_INSTRUCTIONS[kitType]?.CONTENT.map(item => {
        return (
            <Col xs={24} sm={12} md={12} lg={8} key={item}>
            <div className={style.kitListItemContainer}>
                <div>
                    <img src={list_icon} className={style.listItemImage} alt="kit" />
                </div>
                <div className={style.listKitText} >
                        <Text>{item}</Text>
                </div>
            </div>
        </Col>
        )
    })

    const renderStepGap = (noOfSteps) => {
        const EMPTY_STEPS = Array.from({ length: noOfSteps }, (_, index) => index);
        const MediumBarArr = [3,7,11]
        return EMPTY_STEPS.map((item) => (
            <Step
            key={item}
            icon={<div className={`${MediumBarArr.includes(item) ? style.mediumBar : style.smallBar}`}></div>}
            description=""
            />
        ));
    };

    const TAB_OPTIONS = [
        {
            label: KIT_INSTRUCTION_TAB_TITLES[KIT_TYPES.TYPE_A],
            key: KIT_TYPES.TYPE_A,
            onClickhandler: () => setKitType(KIT_TYPES.TYPE_A)
        },
        {
            label: KIT_INSTRUCTION_TAB_TITLES[KIT_TYPES.TYPE_B],
            key: KIT_TYPES.TYPE_B,
            onClickhandler: () => setKitType(KIT_TYPES.TYPE_B)
        },
        {
            label: KIT_INSTRUCTION_TAB_TITLES[KIT_TYPES.TYPE_C],
            key: KIT_TYPES.TYPE_C,
            onClickhandler: () => setKitType(KIT_TYPES.TYPE_C)
        },
        {
            label: KIT_INSTRUCTION_TAB_TITLES[KIT_TYPES.TYPE_D],
            key: KIT_TYPES.TYPE_D,
            onClickhandler: () => setKitType(KIT_TYPES.TYPE_D)
        }
    ]

    const onTabChange = (newActiveKey) => {
        setKitType(newActiveKey);
    };


    return (
        <div className={`${style.videoContainer}`}>
            {isValidKit && myParam && <KitRegisterationStatus kitId={kitId} />}
            <div className={`${style.box} ${isValidKit ? 'bg-sand' : ''} full-width`} style={{ textAlign: 'center' }}>
                <Text className={`h3 font-NeueHGDP-light`}>{KIT_INSTRUCTIONS[kitType]?.KIT_TITLE}</Text>
                <br />
                <br />
                <Text className={style.userSubtitle}>             
                    Things to keep in mind when sampling to receive the most accurate sequencing results.
                </Text>
            </div>

            <div className={`viewBoxWidth ${isValidKit ? 'bg-sand' : ''}`}>
                <div className={style.titleCardContainer}>
                    <PhTabs
                        defaultActiveKey={kitType}
                        onTabChange={onTabChange}
                        tabItems={TAB_OPTIONS}
                    />
                </div>
            </div>
            <div className={`${style.lightBackground} ${isValidKit ? '' : style.RemoveBg}`}>
                <div className={style.instructionSection}>
                    <div className={style.kitItemSection}>
                        <Divider style={{ background: '#000', margin: 0, padding: 0 }} type="horizontal" />
                        <Text className={`${style.userTitle} h4`}>Included in this kit you will find:</Text>
                        <Divider style={{ background: '#000', margin: 0, padding: 0 }} type="horizontal" />
                        <div className={style.kitListOuterContainer}>
                            <Row gutter={[32, 16]}>
                                {renderKitContent}
                            </Row>
                        </div>
                        <Divider style={{ background: '#000', margin: 0, padding: 0 }} type="horizontal" />

                    </div>
                </div>
            </div>


            <div className={style.lightBackground}>
                <div className={style.instructionSection}>

                    <div className={`${style.instructionContent} ${isValidKit ? style.lessPadding : ''}`}>
                        <Text className={`h3 font-NeueHGDP-light`}>Step-by-Step Instructions</Text>
                        <div className={`full-width`} >
                            <Divider className="bg-night no-padding no-margin" type="horizontal" />
                            <div className={`${style.sandContainer} ${style.textAlign}`}>
                                <Space size="middle" direction="vertical" >
                                    <div className={style.textAlign}>
                                        <Text className="text-dark h5">Conduct your skin microbiome test in the morning before cleansing or using any products.</Text>
                                    </div>
                                </Space>
                            </div >
                            <div className={`${style.textAlign} ${style.instructionHeading}`} >
                                <Text className={`note-1 font-Mono-regular`}>
                                    <span className='font-Neue-medium'>PREPARATION</span>: Wash your hands, remove all contents of the box, and put on the pair of sterile gloves included to prevent contamination.

                                </Text>

                                {/* <br />
                                <br />
                                <div className={style.smallTextContainer}><Text className={style.smallText}>NOTE: Nothing in the test kit is hazardous; putting on gloves
                                    ensures that your samples are not contaminated by your hands.
                                </Text></div> */}
                            </div>
                            <Divider className="bg-night no-padding no-margin" type="horizontal" />
                        </div>
                        <div className='verticalStep'>
                            <div className={style.instructionStepper}>
                                <Steps
                                    current={0}
                                    direction="vertical"
                                    className={style.steps}
                                >
                                    {/* step 1 */}
                                    <Step
                                        icon={<div></div>}
                                        description=""
                                    />
                                    
                                    {/* step 10 */}
                                    {  KIT_INSTRUCTIONS[kitType]?.KIT_STEPS.map( item => {
                                        return (
                                            <>
                                                {renderStepGap(isTabOrLower ? item.emptyStep : item.emptystepMobile)}

                                            <Step
                                                icon={<div className={style.LargeBar}></div>}
                                                description={instructionBlock(item.stepNo, item.title, item.imgSrc,
                                                    <div className={style.stepSubtitle} style={{ textAlign: 'left' }}>
                                                        {item.subtitle}
                                                    </div>
                                                )}
                                            />

                                            </>
                                        )
                                    })
                                    }
                                    
                                    {renderStepGap(isTabOrLower ? 6 : 10)}
                                    <Step
                                        icon={<div className={style.LargeBar}></div>}
                                        description={
                                            <div className={style.contactInfo}>
                                                <Text className={`${style.question} h1`}>Questions?</Text>
                                                <Text className={style.userSubtitle} style={{ textAlign: "left" }}> Write us:
                                                    <Link className={style.link} href="mailto:support@parallelhealth.io">support@parallelhealth.io</Link> </Text>
                                            </div>
                                        }
                                    />
                                    {renderStepGap(1)}
                                    <Step
                                        icon={<div></div>}
                                        description=""
                                    />
                                </Steps>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={style.explorePHsection}>
                <Text className={`h4`}>Once completed, let’s explore our parallel world together</Text>
                <Button className={style.button} type="primary" onClick={()=> navigate("/dashboard")}>
                    My Dashboard
                    <img src={button_arrow} className='ph-btn-arrow' />
                </Button>
            </div>
            
            {showHealthProfile && !myParam && (
                <div className={`${style.stickyfooter} show-only-on-desktop`}>
                    <ExclamationCircleFilled className={style.warningIcon} />
                    <Text className="body-txt-md text-light medium-text">
                        ACTION REQUIRED: &nbsp; Please complete your <Link href={`/healthprofile-form/${kitId}`} className={`body-txt-md text-light medium-text`} >Skin Health Profile
                            <img src={button_arrow} className='ph-btn-arrow' /></Link>
                    </Text>

                </div>
            )}
        </div>
    )
}

export default KitUserGuide