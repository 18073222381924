import { Outlet } from 'react-router-dom';
import AdminComponent from '@ph/admin/src/component';
import { Layout } from 'antd';
import './App.scss';
import React, { useEffect, useState } from "react";
import ServiceDashboardSidebar from 'src/component/global/Sidebars/ServiceDashboardSidebar';
import { getKitTypes } from 'src/component/AdminServiceDashboard/ServiceDashboardAPIService';
import { handleErrorMsg } from '@ph/common/global/Helpers';

const { Content } = Layout;

function ServiceDashboardLayout() {
    const [refreshSideBarCount, setRefreshSideBarCount] = useState(false);
    const [kitTypeList, setKitTypeList] = useState([])

    const kitTypesResnponseHandler = (res) => {
        if (!res?.data) {
            return
        }
        const resOptions = []
        res.data.map(kitType => resOptions.push({
            id: kitType.kit_type_id,
            name: kitType.kit_type_name,
            value: kitType.kit_type_id,
            label: `Kit Type ${kitType.kit_type_id}`
        }))
        const sortedKitTypeArray = [...resOptions].sort((a, b) => a.id.localeCompare(b.id));
        setKitTypeList(sortedKitTypeArray)
    }

    const fetchKitTypes = () => {
        getKitTypes()
            .then(kitTypesResnponseHandler)
            .catch(error => handleErrorMsg(error, "Something went wrong while fetching kit types!"))
    }

    useEffect(() => {
        fetchKitTypes()
    }, [])

    return (
        <>
            <AdminComponent.AdminHeader title="Service Dashboard"
                searchKit={false} scanKit={false} headerNav={false} />
            <section className="section">
                <div className='container' style={{ padding: 0 }}>
                    <Layout>
                        {<ServiceDashboardSidebar {...{ refreshSideBarCount, setRefreshSideBarCount }} />}
                        <Layout>
                            <Content className='admin-portal-main-content-wrapper'>
                                <Outlet context={[refreshSideBarCount, setRefreshSideBarCount, kitTypeList]} />
                            </Content>
                        </Layout>
                    </Layout>
                </div>
            </section>
        </>
    );
}

export default ServiceDashboardLayout;