import { useEffect, useState } from 'react';
import { Typography, Row, Col, Button } from 'antd';
import style from './ReportContainer.module.scss';
import { useCart } from '@ph/common/context/CartContext';
import PHIncrementSelectButton from '../../Global/PHIncrementSelectButton/PHIncrementSelectButton';
import RecommendedText from './RecommendedText';
import ProductPrice from './ProductPrice';
import { REPORT_PLAN_DETAILS } from '@ph/common/constants/Report/ReportConstants';
import { updateReportDefaultSerum } from '@ph/admin/src/component/AdminLabPortal/AdminPortalAPIService';
import { handleErrorMsg } from '@ph/common/global/Helpers';
import { useParams } from 'react-router-dom';
const { Text } = Typography


function ReportProduct({
    product,
    title,
    first = false,
    backgroundColor = '#FFFFFF',
    hoverColor = '#f5f1f0',
    textColor = '#2D2926',
    textHoverColor = '#2D2926',
    discountBg = '#2D2926',
    discountTextColor = '#FFFFFF',
    discountTextHover = '#FFFFFF',
    discountBgHover = '#2D2926',
    reportPlan,
    isAdmin = false,
    current_serum,
    getReportData = null
}) {
    const params = useParams();
    const [productQuantity, setProductQuantity] = useState()
    const { products, addProduct, removeProduct, updateQuantity } = useCart();
    const [loading, setLoading] = useState(false)

    const styles = {
        '--background-color': backgroundColor,
        '--hover-color': hoverColor,
        '--text-color': textColor,
        '--text-hover-color': textHoverColor,
        '--discount-bg': discountBg,
        '--discount-bg-hover': discountBgHover,
        '--discount-text': discountTextColor,
        '--discount-text-hover': discountTextHover
    }

    useEffect(() => {
        const isPresent = products.filter(currProduct => currProduct?.product_id === product?.product_id)
        if (isPresent && isPresent.length > 0) {
            setProductQuantity(isPresent[0].quantity)
        } else {
            setProductQuantity(0)
        }
    }, [products, productQuantity])

    const handleOnMinusClick = () => {
        setProductQuantity(productQuantity - 1)
        updateQuantity(product.product_id, productQuantity - 1);
    }

    const handleOnPlusClick = () => {
        setProductQuantity(productQuantity + 1)
        updateQuantity(product.product_id, productQuantity + 1);

    }

    const handleProductCheckbox = () => {
        if (productQuantity) {
            setProductQuantity(0)
            removeProduct(product.product_id)
        } else {
            setProductQuantity(1)
            addProduct({ ...product, quantity: 1 });
        }
    }

    function addToStart(arr, newItem) {
        const itemsWithoutNewItem = arr.filter(item => item != newItem);
        itemsWithoutNewItem.unshift(newItem);
        return itemsWithoutNewItem;
    }

    const chooseDefault = async () => {
        const all_order_list = ['TZ', 'CK', 'CN']
        const order_list = addToStart(all_order_list, current_serum);
        const kitcode = params.kitId.toUpperCase();

        try {
            const res = await updateReportDefaultSerum(kitcode, { order_list: order_list });

            if (res.data) {
                setLoading(true);
                await getReportData(kitcode, false)
                setLoading(false);
            }
        } catch (error) {
            handleErrorMsg(error, "Something went wrong!");
        }
    };


    const discountPrice = product && product[REPORT_PLAN_DETAILS[reportPlan]?.original_price_key] !== undefined ? product[REPORT_PLAN_DETAILS[reportPlan]?.original_price_key] : null;
    const originalPrice = product && product[REPORT_PLAN_DETAILS[reportPlan]?.discount_price_key] !== undefined ? product[REPORT_PLAN_DETAILS[reportPlan]?.discount_price_key] : null;


    return (
        <div className={`${style.reportProduct} ${productQuantity ? style.selected : ''} ${first ? style.first : ''}`} style={styles}>
            <div>
                <Text className={style.title}>{title}</Text>
            </div>
            <div>
                <Row style={{ width: '100%' }}>
                    <Col xs={15} md={12}>
                        <Text className={style.productName}>{product?.name}</Text>
                    </Col>
                    <Col xs={9} md={12}>
                        <ProductPrice originalPrice={originalPrice} discountPrice={discountPrice} reportPlan={reportPlan} />
                    </Col>
                </Row>
                {product?.is_included && <RecommendedText />}
            </div>
            <div>
                <div className={style.productDesc}>{product?.description?.short_text}</div>
            </div>
            {!product?.is_included && isAdmin && (
                <>
                    <div className={style.adminButtonContainer}></div>
                    <div className={style.actionButtonContainer}>
                        <Button className={style.actionButton} onClick={chooseDefault} loading={loading}>CHOOSE AS DEFAULT SERUM</Button>
                    </div>
                </>
            )}

            <div className={style.buttonContainer}>
                <PHIncrementSelectButton
                    productQuantity={productQuantity}
                    handleOnMinusClick={handleOnMinusClick}
                    handleOnPlusClick={handleOnPlusClick}
                    handleCheckbox={handleProductCheckbox}
                    included={product?.is_included}
                    className={style.quantityButton}
                />
            </div>
        </div>
    )
}

export default ReportProduct
