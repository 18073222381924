import { useEffect, useState } from 'react';
import { Typography, Row, Col, Button, Grid } from 'antd';
import style from './ReportContainer.module.scss';
import { useCart } from '@ph/common/context/CartContext';
import PHIncrementSelectButton from '../../Global/PHIncrementSelectButton/PHIncrementSelectButton';
import { capitalizeFirstLetter } from '../reportUtils';
import { REPORT_PLAN_DETAILS } from '@ph/common/constants/Report/ReportConstants';
import ProductHelpLabel from './ProductHelpLabel';
import ProductPrice from './ProductPrice';
import RecommendedText from './RecommendedText';

const { Text } = Typography
const { useBreakpoint } = Grid;


function ReportSingleProduct({ product, title = 'PRODUCT HIGHLIGHT', actionButton, allowSelect = true, reportPlan, compact = false, discountBg = '#2D2926',
    discountTextColor = '#FFFFFF',
    discountTextHover = '#FFFFFF',
    discountBgHover = '#2D2926',hideImage = false }) {
    const [productQuantity, setProductQuantity] = useState(0)
    const { products, addProduct, removeProduct, updateQuantity } = useCart();
    const screens = useBreakpoint();
    const isMobile = screens.xs

    const styles = {
        '--discount-bg': discountBg,
        '--discount-bg-hover': discountBgHover,
        '--discount-text': discountTextColor,
        '--discount-text-hover': discountTextHover
    }

    useEffect(() => {
        const isPresent = products.filter(currProduct => currProduct.product_id === product.product_id)
        if (isPresent && isPresent.length > 0) {
            setProductQuantity(isPresent[0].quantity)
        } else {
            setProductQuantity(0)
        }
    }, [products, productQuantity])


    const handleOnMinusClick = () => {
        setProductQuantity(productQuantity - 1)
        updateQuantity(product.product_id, productQuantity - 1);

    }

    const handleOnPlusClick = () => {
        setProductQuantity(productQuantity + 1)
        updateQuantity(product.product_id, productQuantity + 1);

    }

    const handleProductCheckbox = () => {
        if (productQuantity) {
            setProductQuantity(0)
            removeProduct(product.product_id)
        } else {
            setProductQuantity(1)
            addProduct({ ...product, quantity: 1 });
        }
    }

    const discountPrice = product && product[REPORT_PLAN_DETAILS[reportPlan]?.original_price_key] !== undefined ? product[REPORT_PLAN_DETAILS[reportPlan]?.original_price_key] : null;
    const originalPrice = product && product[REPORT_PLAN_DETAILS[reportPlan]?.discount_price_key] !== undefined ? product[REPORT_PLAN_DETAILS[reportPlan]?.discount_price_key] : null;


    return (
        <Row className={`${style.reportSingleProduct} ${productQuantity ? style.selected : ''}`} style={styles}>
            <Col xs={24} xl={6} className={style.productCol}>
                <Text className={style.title}>{title}</Text>
                <div className={style.nameContainer}>
                    <Text className={style.productName}>{product?.name}</Text>
                </div>
            </Col>
            <Col xs={24} xl={hideImage ? 13 : 10 - (actionButton?.colSpan ? actionButton?.colSpan : 0)} className={style.descriptionCol}>
                
                <Col xs={!compact ? 24 : isMobile ? 14 : 24}>
                    {product?.is_included && <div style={{marginBottom: '4px'}}><RecommendedText /></div>}
                    <div className={style.productDesc}>{capitalizeFirstLetter(product?.description?.short_text)}</div>
                </Col>
                {compact && isMobile && (
                    <Col xs={10} className={`${style.imageCol} show-mobile-hide-desktop`}>
                        <img src={product?.image_url} />
                    </Col>
                )}
            </Col>
            {actionButton && actionButton?.text && (
                <Col xs={24} md={actionButton?.colSpan} className={style.descriptionCol}>
                    <Button onClick={actionButton.onClick} className={style.actionButton}>{actionButton?.text}</Button>
                </Col>
            )}


            <Col xs={24} xl={hideImage ? 5 : 8}>
                <Row className={`${style.dividerRow} ${(compact && isMobile) ? style.nonImage : ''}`}>
                    {(!compact || !isMobile) && !hideImage && (
                        <Col xs={8} sm={6} md={5} xl={10} className={style.imageCol}>
                            <img src={product?.image_url} />
                        </Col>
                    )}
                    <Col xs={16} sm={18} md={19} xl={hideImage ? 24 : 14} className={style.labelCol}>
                        {product?.price && (
                            <ProductPrice originalPrice={originalPrice} discountPrice={discountPrice} reportPlan={reportPlan} />
                        )}
                        <div className={style.labelRow}>
                            {/* {allowSelect && REPORT_PLAN_DETAILS[reportPlan]?.discount_suggestion_text_short &&
                                <ProductHelpLabel text={REPORT_PLAN_DETAILS[reportPlan]?.discount_suggestion_text_short} justify='left' />
                            } */}
                        </div>
                    </Col>
                </Row>
                {allowSelect && (
                    <div className={style.buttonContainer}>
                        <PHIncrementSelectButton
                            productQuantity={productQuantity}
                            handleOnMinusClick={handleOnMinusClick}
                            handleOnPlusClick={handleOnPlusClick}
                            handleCheckbox={handleProductCheckbox}
                            included={product?.is_included}
                        />
                    </div>
                )}
            </Col>
        </Row >
    )
}

export default ReportSingleProduct