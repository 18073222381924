import React from 'react';
import style from '../ReportV1/reportv1.module.scss';
import style1 from "../Report.module.scss";


import { Row, Col,Typography } from 'antd';
import {
    report_score_hex,
    report_skin_age,
    hydration_summary,
    sensitivity_summary,
    antibiotic_image
} from '@ph/common/assets';
import { addLeadingZero } from '../reportUtils';
import { REPORT_PAGE_CONFIG, REPORT_SECTIONS } from '../../../constants/Report/ReportConstants';

const {Text} = Typography


  
                    
const SUMMARY_DISPLAY_CONFIG = {
    [REPORT_SECTIONS.DIVERSITY]: {
        headerClassname: '',
        headerTitleClassname: `${style.first}`,
        headerTitle: 'DIVERSITY',
        imageSource: report_score_hex,
        imageClassname: `${style.hexIcon}`,
        scoreTextClassname: 'text-light',
        scoreKey: 'microbiome_diversity',
    },
    [REPORT_SECTIONS.SKIN_AGE] : {
        headerClassname: `${style.scoreHalfColumn}`,
        headerTitleClassname: '',
        headerTitle: 'SKIN AGE',
        imageSource: report_skin_age,
        imageClassname: '',
        scoreTextClassname: '',
        scoreKey: 'skin_age',
    },
    [REPORT_SECTIONS.HYDRATION]: {
        headerClassname: `${style.scoreHalfColumn}`,
        headerTitleClassname: '',
        headerTitle: 'HYDRATION',
        imageSource: hydration_summary,
        imageClassname: `${style.smallImage}`,
        scoreTextClassname: '',
        scoreKey: 'average_hydration',
    },
    [REPORT_SECTIONS.SENSITIVITY]: {
        headerClassname: `${style.scoreHalfColumn}`,
        headerTitleClassname: `${style.bigTitle}`,
        headerTitle: 'SENSITIVITY',
        imageSource: sensitivity_summary,
        imageClassname: `${style.smallImage} ${style.sensitivityImage}`,
        scoreTextClassname: '',
        scoreKey: 'sensitivity',
    },
    [REPORT_SECTIONS.ANTIBIOTIC]: {
        headerClassname: `${style.scoreHalfColumn} ${style.last}`,
        headerTitleClassname: `${style.antiText} ${style.bigTitle}`,
        headerTitle: 'ANTIBIOTIC RESISTANCE',
        imageSource: antibiotic_image,
        imageClassname: `${style.capsuleImage}`,
        scoreTextClassname: '',
        scoreKey: 'antibiotic_resistance',
    }
}

function SummarySection({ reportData, kitType }) {

    const handleLookup = (scoreKey) => {
        const value = reportData[scoreKey];
        if (value && parseInt(value) > 0) {
            return addLeadingZero(parseInt(value))
        } else {
            return 'NA'
        }
    };

    const miniSummary = REPORT_PAGE_CONFIG[kitType]?.allowedSections.length > 3 ? false : true

    const styles = {
        '--score-col': miniSummary ? 3 : 5
    }

    return (
        // Summary Section
        <>
            <Row align="stretch" gutter={[40, 20]} className={style.scoreHeadingSection}>
                <Col xs={24}>
                    <Text className={style.scoreHeading}>Score Summary</Text>
                </Col>
            </Row>
            <Row align="stretch" gutter={[40, 20]} className={style.scoreHeadingSection}>
                <Col xs={24} xl={miniSummary ? 10 : 24}>
                    <Row gutter={[40, 20]}>
                        <Col xs={24} lg={miniSummary ? 24 : 12} style={{ textAlign: "justify" }}>
                            <div className={`${style1.bodyText1}`}>
                                {REPORT_PAGE_CONFIG[kitType]?.scoreSectionParaFirst}
                            </div>
                        </Col>
                        <Col xs={24} lg={miniSummary ? 24 : 12} style={{ textAlign: "justify" }}>
                            <div className={`${style1.bodyText1}`}>
                                {REPORT_PAGE_CONFIG[kitType]?.scoreSectionParaSecond}
                            </div>
                        </Col>
                    </Row>
                </Col>
                
                <Col xs={24} xl={miniSummary ? 14 : 24}>
                    <div className={style.scoreSection} style={styles}>
                        <Row className={`${style.row} ${style.headerRow} show-desktop-hide-mobile`}>
                            {
                                REPORT_PAGE_CONFIG[kitType]?.allowedSections.map(section => (
                                    <HeaderRow
                                        {...SUMMARY_DISPLAY_CONFIG[section]}
                                        key={section}
                                    />
                                ))
                            }
                        </Row>
                        <Row className={style.row}>
                            {
                                REPORT_PAGE_CONFIG[kitType]?.allowedSections.map(section => (
                                    <ScoreRow
                                        {...SUMMARY_DISPLAY_CONFIG[section]}
                                        key={section}
                                        scoreValue={handleLookup(SUMMARY_DISPLAY_CONFIG[section].scoreKey)}
                                    />
                                ))
                            }
                        </Row>
                    </div>
                </Col>
            </Row>

            
        </>
    )
}

export default SummarySection

const HeaderRow = ({ headerClassname, headerTitleClassname, headerTitle }) => {
    return (
        <Col xs={24} md={5} className={`${style.scoreCol} ${headerClassname}`}>
            <div className={`${style.scoreTitle} ${headerTitleClassname}`}>
                {headerTitle}
            </div>
        </Col>
    )
}

const ScoreRow = ({ headerClassname, headerTitleClassname, headerTitle, imageSource, imageClassname, scoreTextClassname, scoreValue }) => {
    return (
        <Col xs={24} md={5} className={`${style.scoreCol} ${headerClassname}`}>
            <div className={`${style.scoreTitle} ${headerTitleClassname} show-mobile-hide-desktop`}>
                {headerTitle}
            </div>
            <div className={`${style.scoreContainer}`}>
                <img src={imageSource} className={`${style.scoreImage} ${imageClassname}`} />
                <span className={`${style.scoreText} ${scoreTextClassname}`}>{scoreValue}</span>
            </div>
        </Col>

    )
}
